import CheckboxRedux from "components/molecules/CheckboxRedux";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import ReduxFieldArray from "components/organisms/ReduxFieldArray";
import SelectExtraName from "components/organisms/SelectExtraName";
import { number, required } from "utils/validate.util";

export { default } from "./AddCarExtraForm";

export const fields: ReduxFormField[] = [
  {
    name: "extras",
    label: "Extra",
    component: ReduxFieldArray,
    reduxFormComponent: "FieldArray",
    fieldsArray: [
      {
        name: "isActive",
        label: "Active",
        component: CheckboxRedux,
        cellProps: { md: 3, lg: 3 },
        CheckBoxProps: { defaultChecked: true },
      },
      {
        name: "name",
        label: "Name",
        validate: [required],
        component: SelectExtraName,
        cellProps: { md: 3, lg: 3 },
      },
      {
        name: "dailyRate",
        label: "Daily Rate",
        validate: [required, number],
        component: InputRedux,
        cellProps: { md: 3, lg: 3 },
      },
      {
        name: "maxBuyingCapacity",
        label: "Max Buying Capacity",
        validate: [required, number],
        component: InputRedux,
        cellProps: { md: 3, lg: 3 },
      },
      {
        name: "info",
        label: "Info",
        component: InputRedux,
        cellProps: { md: 12, lg: 12 },
      },
      {
        name: "description",
        label: "Description",
        component: InputRedux,
        cellProps: { md: 12, lg: 12 },
        InputProps: { multiline: true, rows: 3 },
      },
    ],
  },
];
