import { config } from "config";
import {
  SetUserFiltersPayload,
  SetTotalCountPayload,
  SetUsersPayload,
  UserState,
  SetPagePayload,
} from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: UserState = {
  tab: 0,
  refresh: 0,
  refreshLoader: false,
  count: 0,
  totalCount: 0,
  documents: [],
  user: null,
  loading: true,
  userOptions: [],
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clear: (state) => {
      state.userOptions = [];
    },
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addUser: (state, action) => {
      state.documents.unshift(action.payload);
    },
    updateUser: (state, action) => {
      const { id, user } = action.payload;
      state.documents.every(({ _id }, i) => {
        if (id === _id) {
          state.documents[i] = user;
          return false;
        }
        return true;
      });
    },
    deleteUserById: (state, action) => {
      const id = action.payload;
      state.documents.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.documents.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action: PayloadAction<SetUsersPayload>) => {
      const { documents } = action.payload;

      let options: SelectOption[] = [];

      documents.forEach(({ _id, firstName, lastName }: any) => {
        const option = { value: _id, label: `${firstName} ${lastName}` };
        options.push(option);
      });

      state.documents = documents;
      state.userOptions = options;
      state.refreshLoader = false;
    },
    setTotalCount: (state, action: PayloadAction<SetTotalCountPayload>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
      // state.refresh += 1;
      // state.refreshLoader = true;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action: PayloadAction<SetUserFiltersPayload>) => {
      const { data } = action.payload;
      state.filters = data;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const userReducer = userSlice.reducer;

export const userActions = userSlice.actions;
export default userReducer;
