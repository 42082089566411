import { useEffect } from "react";
import { Container, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./FranchiseDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import FranchiseService from "services/franchise.service";
import { franchiseActions } from "redux/slices/franchise";
import DateService from "utils/date.util";

export default function FranchiseDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const franchise = useAppSelector((state) => state.franchise.franchise);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    FranchiseService.getFranchise(id || "");
    return () => {
      dispatch(franchiseActions.setFranchise(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/franchises" title="Back to Franchise" />
      <PageHeader title="Franchise details" />
      {loading && <CircleLoader />}
      {id && !franchise ? (
        <p>Franchise Not Found</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={franchise?.currentStatus} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-franchise/${franchise._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <div className={styles.fieldBox}>
            <label htmlFor="">Franchise ID</label>
            <p>{franchise?.docNumber}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Franchise Code</label>
            <p>{franchise?.franchiseCode}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Name</label>
            <p>{franchise?.name}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Phone</label>
            <p>
              +{franchise?.phoneNumber?.dialingCode}
              {franchise?.phoneNumber.number}
            </p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Email</label>
            <p>{franchise?.email}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Main Contact Person</label>
            <p>{franchise?.mainContactPerson}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Company</label>
            <br />
            <Link
              to={`/company-details/${franchise?.companyId?._id}`}
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {franchise?.companyId?.name}
            </Link>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Number of Employees</label>
            <p>{franchise?.numberOfEmployees}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Address</label>
            <p>{franchise?.address?.formattedAddress}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Date Of Establishment</label>
            <p>{DateService.getDateString(franchise?.dateOfEstablishment)}</p>
          </div>
        </div>
      )}
    </Container>
  );
}
