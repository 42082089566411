import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { companyActions } from "redux/slices/company";
import ImageService from "./image.service";

const url = "/api/b2b/company";

const CompanyService = {
  getCompany: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const company = success.data;
      dispatch?.(companyActions.setCompany(company));
    } else dispatch?.(companyActions.setCompany({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCompanies: async (data: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(companyActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(companyActions.setCompanies({ documents }));
      dispatch?.(companyActions.setTotalCount({ totalCount }));
    }

    dispatch?.(companyActions.setLoading(false));
    return [success, error];
  },

  addCompany: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();
    const formData = new FormData();

    // Convert logoUrl to a file if it's not a string
    if (typeof data.logoUrl !== "string") {
      let img = await ImageService.getImageFileFromBlob(data.logoUrl);
      formData.append("photo", img);
    }

    for (const key of Object.keys(data)) {
      if (key === "phoneNumber")
        formData.append("phoneNumber", JSON.stringify(data.phoneNumber));
      else if (key === "address")
        formData.append("address", JSON.stringify(data.address));
      else formData.append(key, data[key]);
    }

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, formData)
    );

    if (success) {
      const company = success.data;
      dispatch?.(companyActions.addCompany(company));
      navigate?.("/companies");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCompany: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));
    const formData = new FormData();

    if (typeof data?.logoUrl !== "string") {
      let img = await ImageService.getImageFileFromBlob(data.logoUrl);
      formData.append("photo", img);
    }

    for (const key of Object.keys(data)) {
      if (key === "phoneNumber")
        formData.append("phoneNumber", JSON.stringify(data.phoneNumber));
      else if (key === "address")
        formData.append("address", JSON.stringify(data.address));
      else formData.append(key, data[key]);
    }

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, formData)
    );

    if (success) {
      const company = success.data;
      dispatch?.(companyActions.setCompany(company));
      navigate?.(`/company-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteCompany: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(companyActions.deleteCompanyById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CompanyService;
