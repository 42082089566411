import { PricesState, SetPricesPayload } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetPagePayload } from "../user";
import { config } from "config";

const default_page_size = config.PAGE_SIZE;
const initialState: PricesState = {
  prices: [],
  price: null,
  totalCount: 0,
  refresh: 0,
  refreshLoader: false,
  loading: true,
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
};

export const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addPrice: (state, action) => {
      state.prices.unshift(action.payload);
    },
    updatePrice: (state, action) => {
      const { id, price } = action.payload;
      state.prices.every(({ _id }, i) => {
        if (id === _id) {
          state.prices[i] = price;
          return false;
        }
        return true;
      });
    },
    deletePriceById: (state, action) => {
      const id = action.payload;
      state.prices.every(({ _id }, i) => {
        if (id === _id) {
          state.prices.splice(i, 1);
          return false;
        }
        return true;
      });
    },

    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setPrices: (state, action: PayloadAction<SetPricesPayload>) => {
      const { prices, totalCount } = action.payload;

      state.prices = prices;
      state.totalCount = totalCount;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const priceReducer = priceSlice.reducer;

export const priceActions = priceSlice.actions;
export default priceReducer;
