import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import CarPriceService from "services/carPrice.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import AddCarPriceForm from "./AddCarPriceForm";
import UpdateCarPriceForm from "./UpdateCarPriceForm";

export default function AddCarPrice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const carPrice = useAppSelector((state) => state.carPrice.carPrice);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };

    data.dailyRate = Number(values.dailyRate);
    data.weeklyRate = Number(values.weeklyRate);
    data.monthlyRate = Number(values.monthlyRate);
    data.isUnlimitedKM = Boolean(values.isUnlimitedKM);
    data.extraKMCost = Number(values.extraKMCost);
    data.freeKMPerDay = Number(values.freeKMPerDay);

    if (id) {
      CarPriceService.updateCarPrice(`${id}`, data, navigate);
    } else CarPriceService.addCarPrice(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${
            id && carPrice ? `/car-price-details/${id}` : "/car-prices"
          }`}
          title={`Back to ${
            id && carPrice ? "Car Price Details" : "Car Prices"
          } `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Car Price`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && carPrice === "Not found" ? (
            <p>Car Price Not Found</p>
          ) : (
            <AddCarPriceForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateCarPriceForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
