import { useEffect } from "react";
import { Container, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./EmployeeDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import EmployeeService from "services/employee.service";
import { employeeActions } from "redux/slices/employee";

export default function EmployeeDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const employee = useAppSelector((state) => state.employee.employee);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    EmployeeService.getEmployee(id || "");
    return () => {
      dispatch(employeeActions.setEmployee(null));
    };
  }, [id, dispatch]);

  console.log("employee=>", employee);


  return (
    <Container maxWidth="xl">
      <GoBack path="/employees" title="Back to Employee" />
      <PageHeader title="Employee details" />
      {loading && <CircleLoader />}
      {!loading && !employee ? (
        <p>Employee Not Found</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={employee?.currentStatus} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-employee/${employee._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <div className={styles.fieldBox}>
            <label htmlFor="">Employee ID</label>
            <p>{employee?.employeeNumber}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Name</label>
            <p>
              {employee?.firstName} {employee?.lastName}
            </p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Phone</label>
            <p>
              +{employee?.phoneNumber?.dialingCode}
              {employee?.phoneNumber.number}
            </p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Email</label>
            <p>{employee?.email}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Designation</label>
            <p>{employee?.designation}</p>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Company</label>
            <br />
            <Link
              to={`/company-details/${employee?.companyId?._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {employee?.companyId?._id}
            </Link>
          </div>
          <div className={styles.fieldBox}>
            <label htmlFor="">Franchise</label>
            <br />
            <Link
              to={`/franchise-details/${employee?.franchiseId?._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {employee?.franchiseId?._id}
            </Link>
          </div>
        </div>
      )}
    </Container>
  );
}
