import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import SupplierService from "services/supplier.service";
import { supplierActions } from "redux/slices/supplier";

export default function UpdateSupplierForm({ id }: any) {
  const form = "AddSupplierForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const supplier = useAppSelector((state) => state.supplier.supplier);

  useEffect(() => {
    SupplierService.getSupplier(id || "");

    return () => {
      dispatch(supplierActions.setSupplier(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!supplier) return;
    else {
      const {
        businessLogo,
        name,
        legalName,
        mainContactPerson,
        phoneNumber,
        email,
        registrationNumber,
        taxId,
        numberOfVehicles,
        address,
        description,
      } = supplier;

      dispatch(change(form, "businessLogo", businessLogo));
      dispatch(change(form, "name", name));
      dispatch(change(form, "legalName", legalName));
      dispatch(change(form, "mainContactPerson", mainContactPerson));
      dispatch(change(form, "email", email));
      dispatch(change(form, "registrationNumber", registrationNumber));
      dispatch(
        change(form, "phone", {
          value: phoneNumber?.dialingCode + phoneNumber?.number,
          data: {
            dialCode: phoneNumber?.dialingCode,
          },
        })
      );
      dispatch(
        change(form, "address", { value: address?.formattedAddress, address })
      );
      dispatch(change(form, "taxId", taxId));
      dispatch(change(form, "numberOfVehicles", numberOfVehicles));
      dispatch(change(form, "description", description));
    }
  }, [supplier, navigate, dispatch]);

  return null;
}
