import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { number, required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import SelectCarPrice from "components/organisms/SelectCarPrice";

export { default } from "./AddCarForm";

export const fields: ReduxFormField[] = [
  {
    name: "carPricingId",
    label: "Pricing Template",
    // validate: [required],
    component: SelectCarPrice,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "manufacturingYear",
    label: "Manufacturing Year",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 3, lg: 3 },
  },
  {
    name: "numberOfCars",
    label: "Number Of Cars",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 3, lg: 3 },
  },
  {
    name: "isAirConditioned",
    label: "Air Conditioned",
    component: CheckboxRedux,
    cellProps: { md: 2 },
    // CheckBoxProps: {
    //   disabled: true,
    // },
  },
  {
    name: "fuelType",
    label: "Fuel Type",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "petrol", label: "Petrol" },
        { value: "electric", label: "Electric" },
        { value: "hybrid", label: "Hybrid" },
      ],
    },
  },
  {
    name: "transmission",
    label: "Transmission",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "auto", label: "Auto" },
        { value: "manual", label: "Manual" },
      ],
    },
  },
  {
    name: "numberOfSeats",
    label: "Number Of Seats",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfBags",
    label: "Number Of Bags",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfDoors",
    label: "Number Of Doors",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
];

export const priceFields: ReduxFormField[] = [
  {
    name: "isUnlimitedKM",
    label: "Unlimited KM",
    component: CheckboxRedux,
    cellProps: { md: 2 },
  },
  // {
  //   name: "name",
  //   label: "Name",
  //   validate: [required],
  //   component: InputRedux,
  //   cellProps: { md: 2 },
  // },
  {
    name: "dailyRate",
    label: "Daily Rate",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "weeklyRate",
    label: "Weekly Rate",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "monthlyRate",
    label: "Monthly Rate",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "extraKMCost",
    label: "Extra KM Cost",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "freeKMPerDay",
    label: "Free KM Per Day",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
];
