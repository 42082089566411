import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { promotionActions } from "redux/slices/promotion";

const url = "/api/promotions";

const PromotionService = {
  getPromotion: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/by-id/${id}`)
    );

    let promotion: any = null;
    if (success) {
      promotion = success?.data ?? null;
    }
    dispatch?.(promotionActions.setPromotion(promotion));
    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getPromotions: async (data: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(promotionActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(promotionActions.setPromotions({ documents }));
      dispatch?.(promotionActions.setTotalCount({ totalCount }));
    }

    dispatch?.(promotionActions.setLoading(false));
    return [success, error];
  },

  addPromotion: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );
    if (success) {
      const promotion = success?.data ?? null;
      dispatch?.(promotionActions.setPromotion(promotion));
      navigate?.("/promotions");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updatePromotion: async (
    id: string,
    data: any,
    navigate: NavigateFunction
  ) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/by-id/${id}`, data)
    );
    if (success) {
      const promotion = success?.data;
      dispatch?.(promotionActions.setPromotion(promotion));
      navigate?.(`/promotion-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deletePromotion: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(promotionActions.deletePromotionById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default PromotionService;
