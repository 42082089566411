export const calculateTotal = (
  totalPrice: any,
  selectedExtras: any,
  selectedInsurance: any,
  discountType?: any,
  discountValue?: any
) => {
  const extrasSubtotal =
    selectedExtras?.length > 0
      ? selectedExtras?.reduce((acc: any, item: any) => {
          return acc + item.totalAmount;
        }, 0)
      : 0;

  const insuranceSubtotal = selectedInsurance?.id
    ? selectedInsurance?.totalAmount
    : 0;

  let totalSubtotal = totalPrice + extrasSubtotal + insuranceSubtotal;

  const dValue = Number(discountValue) || 0;
  let discount = 0;
  if (discountType === "percentage") {
    discount = (dValue / 100) * totalSubtotal;
  } else if (discountType === "amount") {
    discount = dValue;
  } else discount = 0;

  totalSubtotal = totalSubtotal - discount;

  // Calculate VAT (15%)
  const vat = totalSubtotal * 0.15;

  // Calculate Net Price (Total including VAT)
  const netPrice = totalSubtotal - vat;

  // Format prices to 2 decimal places
  const formattedTotalSubtotal = totalSubtotal.toFixed(2);
  const formattedVat = vat.toFixed(2);
  const formattedNetPrice = netPrice.toFixed(2);
  const formattedDiscount = discount.toFixed(2);

  return {
    totalSubtotal: formattedTotalSubtotal,
    vat: formattedVat,
    netPrice: formattedNetPrice,
    discount: formattedDiscount,
  };
};