import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarSpecsService from "services/carSpec.service";
import { carSpecActions } from "redux/slices/carSpec";

export default function UpdateCarSpecsForm({ id }: any) {
  const form = "AddCarSpecsForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carSpec = useAppSelector((state) => state.carSpec.carSpec);

  useEffect(() => {
    CarSpecsService.getCarSpec(id || "");

    return () => {
      dispatch(carSpecActions.setCarSpec(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!carSpec) return;

    const {
      acrissCode,
      carImage,
      unlimitedKM,
      fuelType,
      numberOfSeats,
      numberOfBags,
      numberOfDoors,
      transmission,
      isAirConditioned,
    } = carSpec;

    dispatch(change(form, "acrissCode", acrissCode));
    dispatch(change(form, "carImage", carImage));
    dispatch(change(form, "unlimitedKM", unlimitedKM));
    dispatch(change(form, "fuelType", fuelType));
    dispatch(change(form, "numberOfSeats", numberOfSeats));
    dispatch(change(form, "numberOfBags", numberOfBags));
    dispatch(change(form, "numberOfDoors", numberOfDoors));
    dispatch(change(form, "transmission", transmission));
    dispatch(change(form, "isAirConditioned", isAirConditioned));
  }, [carSpec, navigate, dispatch]);

  return null;
}
