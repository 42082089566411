import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { carSpecActions } from "redux/slices/carSpec";
import ImageService from "./image.service";

const url = "/api/gateway/yelo-car-specs";

const CarSpecService = {
  getCarSpec: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const carSpec = success.data;
      dispatch?.(carSpecActions.setCarSpec(carSpec));
    } else dispatch?.(carSpecActions.setCarSpec({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCarSpecs: async (data: any = {}) => {
    const dispatch = getAppDispatch();

    dispatch?.(carSpecActions.setLoading(true));

    http.setJWT();

    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(
        carSpecActions.setCarSpecs({ carSpecs: documents, totalCount })
      );
    }

    dispatch?.(carSpecActions.setLoading(false));
    return [success, error];
  },

  addCarSpec: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();
    const formData = new FormData();

    // Convert the car image to a file and append it
    let img = await ImageService.getImageFileFromBlob(data.carImage);
    formData.append("photo", img);

    // Append other values to formData
    formData.append("acrissCode", data.acrissCode);
    formData.append("unlimitedKM", data.unlimitedKM);
    formData.append("isAirConditioned", data.isAirConditioned);
    formData.append("fuelType", data.fuelType);
    formData.append("transmission", data.transmission);
    formData.append("numberOfSeats", data.numberOfSeats);
    formData.append("numberOfBags", data.numberOfBags);
    formData.append("numberOfDoors", data.numberOfDoors);

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, formData)
    );

    if (success) {
      const carSpec = success.data;
      dispatch?.(carSpecActions.addCarSpec(carSpec));
      navigate?.("/car-specs");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCarSpec: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setMultiPart();
    const formData = new FormData();

    // Convert businessLogo to a file
    let img = await ImageService.getImageFileFromBlob(data.carImage);

    // Append the image
    formData.append("photo", img);

    // Append other values to formData
    formData.append("acrissCode", data.acrissCode);
    formData.append("unlimitedKM", data.unlimitedKM);
    formData.append("isAirConditioned", data.isAirConditioned);
    formData.append("fuelType", data.fuelType);
    formData.append("transmission", data.transmission);
    formData.append("numberOfSeats", data.numberOfSeats);
    formData.append("numberOfBags", data.numberOfBags);
    formData.append("numberOfDoors", data.numberOfDoors);

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, formData)
    );

    if (success) {
      const carSpec = success.data;
      dispatch?.(carSpecActions.setCarSpec(carSpec));
      navigate?.(`/car-specs-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteCarSpec: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(carSpecActions.deleteCarSpecById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CarSpecService;
