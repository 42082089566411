import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddCarCategoryForm from "./AddCarCategoryForm";
import UpdateCarCategoryForm from "./UpdateCarCategoryForm";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CarCategoryService from "services/carCategory.service";

export default function AddCarCategory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.user);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };

    if (id) {
      CarCategoryService.updateCarCategory(`${id}`, data, navigate);
    } else CarCategoryService.addCarCategory(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && user ? `/cat-details/${id}` : "/car-categories"}`}
          title={`Back to ${id && user ? "Car Details" : "Car Categories"} `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Car Category`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && user === "Not found" ? (
            <p>Car Category Not Found</p>
          ) : (
            <AddCarCategoryForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateCarCategoryForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
