import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { branchActions } from "redux/slices/branch";

const url = "/api/non-api/branch";

const BranchService = {
  getBranch: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const branch = success.data;
      dispatch?.(branchActions.setBranch(branch));
    } else dispatch?.(branchActions.setBranch({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getSupplierBranches: async (id: string) => {
    const dispatch = getAppDispatch();

    dispatch?.(branchActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?supplierId=${id}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(branchActions.setSupplierBranches({ branches: documents }));
      dispatch?.(branchActions.setTotalCount({ totalCount }))
    }

    dispatch?.(branchActions.setLoading(false));
    return [success, error];
  },

  getBranches: async (data: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(branchActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(branchActions.setBranches({ branches: documents }));
      dispatch?.(branchActions.setTotalCount({ totalCount }));
    }

    dispatch?.(branchActions.setLoading(false));
    return [success, error];
  },

  addBranch: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const branch = success.data;
      dispatch?.(branchActions.addBranch(branch));
      navigate?.("/branches");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateBranch: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const branch = success.data;
      dispatch?.(branchActions.setBranch(branch));
      navigate?.(`/branch-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteBranch: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(branchActions.deleteBranchById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default BranchService;
