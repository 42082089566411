import { config } from "config";
import { SetEmployeesPayload, EmployeeState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetTotalCountPayload } from "../booking";
import { SetPagePayload } from "../user";

const default_page_size = config.PAGE_SIZE;
const initialState: EmployeeState = {
  count: 0,
  totalCount: 0,
  documents: [],
  employee: null,
  loading: true,
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
  refresh: 0,
  refreshLoader: false,
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addEmployee: (state, action) => {
      state.documents.unshift(action.payload);
    },
    updateEmployee: (state, action) => {
      const { id, employee } = action.payload;
      state.documents.every(({ _id }, i) => {
        if (id === _id) {
          state.documents[i] = employee;
          return false;
        }
        return true;
      });
    },
    deleteEmployeeById: (state, action) => {
      const id = action.payload;
      state.documents.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.documents.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<SetTotalCountPayload>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
      // state.refresh += 1;
      // state.refreshLoader = true;
    },
    setEmployees: (state, action: PayloadAction<SetEmployeesPayload>) => {
      const { documents } = action.payload;
      state.documents = documents;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const employeeReducer = employeeSlice.reducer;

export const employeeActions = employeeSlice.actions;
export default employeeReducer;
