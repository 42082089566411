import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { length4, number, required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import FileUploadRedux from "components/molecules/FileUploadRedux";

export { default } from "./AddCarSpecsForm";

export const fields: ReduxFormField[] = [
  {
    name: "acrissCode",
    label: "Acriss Code",
    validate: [required, length4],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "carImage",
    label: "Car Image",
    validate: [required],
    component: FileUploadRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "unlimitedKM",
    label: "Unlimited KM",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "isAirConditioned",
    label: "Air Conditioned",
    component: CheckboxRedux,
    cellProps: { md: 2 },
  },
  {
    name: "fuelType",
    label: "Fuel Type",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "petrol", label: "Petrol" },
        { value: "electric", label: "Electric" },
        { value: "hybrid", label: "Hybrid" },
      ],
    },
  },
  {
    name: "transmission",
    label: "Transmission",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "auto", label: "Auto" },
        { value: "Manual", label: "Manual" },
      ],
    },
  },
  {
    name: "numberOfSeats",
    label: "Number Of Seats",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfBags",
    label: "Number Of Bags",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfDoors",
    label: "Number Of Doors",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
];
