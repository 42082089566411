import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import PromotionService from "services/promotion.service";
import { promotionActions } from "redux/slices/promotion";

export default function UpdatePromotionForm({ id }: any) {
  const form = "AddPromotionForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const promotion = useAppSelector((state) => state.promotion.promotion);

  useEffect(() => {
    PromotionService.getPromotion(id || "");

    return () => {
      dispatch(promotionActions.setPromotion(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!promotion) return;

    const {
      title,
      description,
      minOrderAmount,
      usageLimit,
      discountType,
      discountValue,
      emailSpecific,
      modelSpecific,
      locationSpecific,
      dateTimeSpecific,
    } = promotion;

    dispatch(change(form, "title", title));
    dispatch(change(form, "description", description));
    dispatch(change(form, "minOrderAmount", minOrderAmount));
    dispatch(change(form, "usageLimit", usageLimit));
    dispatch(change(form, "discountType", discountType));
    dispatch(change(form, "discountValue", discountValue));
    dispatch(change(form, "emails", emailSpecific?.emails));
    dispatch(change(form, "modelSpecific", modelSpecific?.models));
    dispatch(change(form, "locationSpecific", locationSpecific?.locations));
    dispatch(change(form, "isActiveEmailSpecific", emailSpecific?.isActive));
    dispatch(change(form, "isActiveModelSpecific", modelSpecific?.isActive));
    dispatch(
      change(form, "isActiveLocationSpecific", locationSpecific?.isActive)
    );
    dispatch(
      change(form, "isActiveDateTimeSpecific", dateTimeSpecific?.isActive)
    );

    if (dateTimeSpecific)
      dispatch(
        change(
          form,
          "dateTimeSpecific",
          dateTimeSpecific.dates.map((date: any) => ({
            startTime: {
              error: [null, null],
              date: [
                new Date(date.startTime).toString(),
                new Date(date.endTime).toString(),
              ],
            },
          }))
        )
      );
  }, [promotion, navigate, dispatch]);

  return null;
}
