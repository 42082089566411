import { useEffect } from "react";
import { Container, Grid, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./CarSpecsDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import CarSpecService from "services/carSpec.service";
import { carSpecActions } from "redux/slices/carSpec";

export default function CarSpecDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carSpec = useAppSelector((state) => state.carSpec.carSpec);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    CarSpecService.getCarSpec(id || "");
    return () => {
      dispatch(carSpecActions.setCarSpec(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/car-models" title="Back to Specs" />
      <PageHeader title="Spec details" />
      {loading && <CircleLoader />}
      {!loading && !carSpec ? (
        <p>Specs Not Found</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={carSpec?.currentStatus} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-car-specs/${carSpec._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <Grid container>
            <Grid item md={6}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Specs ID</label>
                <p>{carSpec?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Acriss Code</label>
                <p>{carSpec?.acrissCode}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Fuel Type</label>
                <p style={{ textTransform: "capitalize" }}>
                  {carSpec?.fuelType}
                </p>
              </div>
            </Grid>
            <Grid item md={6}>
              <div style={{ textAlign: "right" }}>
                <img
                  src={carSpec?.carImage}
                  alt={carSpec?.name}
                  style={{ height: "200px", maxWidth: "100%" }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Number Of Seats</label>
                <p>{carSpec?.numberOfSeats}</p>
              </div>
            </Grid>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Number Of Bags</label>
                <p>{carSpec?.numberOfBags}</p>
              </div>
            </Grid>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Number Of Doors</label>
                <p>{carSpec?.numberOfDoors}</p>
              </div>
            </Grid>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Transmission</label>
                <p>{carSpec?.transmission}</p>
              </div>
            </Grid>
            <Grid item md={2.4}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Air Conditioned</label>
                <p>{carSpec?.isAirConditioned ? "Yes" : "No"}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}
