import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { carCategoryActions } from "redux/slices/carCategory";
import CarCategoryService from "services/carCategory.service";
import Chip from "components/atoms/Chip";
import CarCategoryFilters from "../Filters/CarCategoryFilters";
import { config } from "config";

export default function CarCategoriesList({ showFilters }: any) {
  const dispatch = useAppDispatch();
  const carCategories = useAppSelector(
    (state) => state.carCategory.carCategories
  );
  const loading = useAppSelector((state) => state.carCategory.loading);
  const filters = useAppSelector((state) => state.carCategory.filters);
  const refresh = useAppSelector((state) => state.carCategory.refresh);
  const refreshLoader = useAppSelector(
    (state) => state.carCategory.refreshLoader
  );
  const totalCount = useAppSelector((state) => state.carCategory.totalCount);
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE)
  );

  useEffect(() => {
    return () => {
      dispatch(carCategoryActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    CarCategoryService.getCarCategories(data);
  }, [filters, refresh, showFilters]);

  return (
    <>
      <TableContainer>
        {showFilters && <CarCategoryFilters />}
        <Box
          p={3}
          pt={0}
          bgcolor={"#ffffff"}
          borderRadius={"4px"}
          border={"1px solid #d3d3d3"}
        >
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              coloumns={7}
              loading={loading}
              length={
                carCategories.length < 1 && loading ? 0 : carCategories.length
              }
              message="No one has connected with our rental services yet"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>UID</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Description</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carCategories.map((carCategory, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{carCategory?.docNumber}</StyledTableCell>
                    <StyledTableCell>{carCategory?.name}</StyledTableCell>
                    <StyledTableCell>
                      {carCategory?.description}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Chip status={carCategory?.currentStatus} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        className="detail-link"
                        to={`/update-car-category/${carCategory._id}`}
                      >
                        Edit
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
          {!refreshLoader && showFilters && (
            <Stack alignItems={"center"}>
              <Pagination
                variant="outlined"
                shape="rounded"
                page={filters.page}
                count={Math.ceil(totalPages ?? filters.pageSize)}
                onChange={(_e, page) =>
                  dispatch(carCategoryActions.setPage({ page }))
                }
              />
            </Stack>
          )}
        </Box>
      </TableContainer>
    </>
  );
}
