import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import authReducer from "./slices/auth";
import modalReducer from "./slices/modal";
import loaderReducer from "./slices/loader";
import bookingReducer from "./slices/booking";
import ticketReducer from "./slices/ticket/ticketSlice";
import { navigationReducer } from "./slices/sidebar/navigationSlice";
import formLoaderReducer from "./slices/formLoader/formLoaderSlice";
import userReducer from "./slices/user/userSlice";
import priceReducer from "./slices/price/priceSlice";
import companyReducer from "./slices/company/companySlice";
import franchiseReducer from "./slices/franchise/franchiseSlice";
import employeeReducer from "./slices/employee/employeeSlice";
import supplierReducer from "./slices/supplier/supplierSlice";
import branchReducer from "./slices/branch/branchSlice";
import carCategoryReducer from "./slices/carCategory/carCategorySlice";
import carModelReducer from "./slices/carModel/carModelSlice";
import carReducer from "./slices/car/carSlice";
import carPriceReducer from "./slices/carPrice/carPriceSlice";
import carSpecReducer from "./slices/carSpec/carSpecSlice";
import carExtraReducer from "./slices/carExtra/carExtraSlice";
import promotionReducer from "./slices/promotion";
import insuranceReducer from "./slices/insurance";
import addBookingReducer from "./slices/addBooking";
import couponReducer from "./slices/coupon";

const appReducer = combineReducers({
  form: formReducer,

  auth: authReducer,
  user: userReducer,
  insurance: insuranceReducer,
  company: companyReducer,
  promotion: promotionReducer,
  coupons: couponReducer,
  employee: employeeReducer,
  franchise: franchiseReducer,
  supplier: supplierReducer,
  branch: branchReducer,
  car: carReducer,
  carPrice: carPriceReducer,
  carCategory: carCategoryReducer,
  carModel: carModelReducer,
  carSpec: carSpecReducer,
  carExtra: carExtraReducer,
  price: priceReducer,
  modal: modalReducer,
  ticket: ticketReducer,
  loader: loaderReducer,
  booking: bookingReducer,
  addBooking: addBookingReducer,
  formLoader: formLoaderReducer,
  navigation: navigationReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "auth/logout") state = {};
  return appReducer(state, action);
};

export default rootReducer;
