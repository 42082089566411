export const phoneFormat = (phone: any) => {
  const dialCodeLength = phone?.data?.countryCode
    ? phone?.data?.dialCode?.length
    : phone?.dialingCode?.length;
  const phoneNumberWithoutDialCode = phone?.data?.countryCode
    ? phone?.value?.slice(dialCodeLength)
    : phone?.number?.slice(dialCodeLength);

  const phoneNumber = {
    countryCode: phone?.data?.countryCode || phone.countryCode,
    dialingCode: phone?.data?.dialCode || phone?.dialingCode,
    number: phone?.data?.dialCode?.length
      ? phoneNumberWithoutDialCode
      : phone?.number,
  };

  return phoneNumber;
};
