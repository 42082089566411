import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetTicketPayload, SetTicketsPayload, TicketLoadingPayload, TicketState } from ".";

const initialTicket = {
  tickets: [],
  loading: true,
};

const initialState: TicketState = {
  tab: 0,
  ticket: null,
  loading: true,
  active: initialTicket,
  inactive: initialTicket,
};

export const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoading: (state, action: PayloadAction<TicketLoadingPayload>) => {
      const { type, loading } = action.payload;
      state[type].loading = loading;
    },
    setTickets: (state, action: PayloadAction<SetTicketsPayload>) => {
      const { type, tickets } = action.payload;

      state[type].tickets = tickets;
    },
    updateTicketStatus: (
      state,
      action: PayloadAction<SetTicketPayload>
    ) => {
      const { type, ticket } = action.payload;

      if (!ticket?._id) return;

      state[type].tickets = state[type].tickets.filter(
        ({ _id }) => _id !== ticket._id
      );
    },
    updateTicketComment: (
      state,
      action: PayloadAction<SetTicketPayload>
    ) => {
      const { type, ticket } = action.payload;

      if (!ticket?._id) return;

      state[type].tickets = state[type].tickets.filter(
        ({ _id }) => _id !== ticket._id
      );
    },
  },
});

const ticketReducer = ticketSlice.reducer;

export const ticketActions = ticketSlice.actions;
export default ticketReducer;
