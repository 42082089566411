import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { carPriceActions } from "redux/slices/carPrice";
import CarPriceService from "services/carPrice.service";
import Chip from "components/atoms/Chip";
import CarsPriceFilters from "../Filters/CarsPriceFilters";
import { config } from "config";

export default function CarPricesList({ showFilters }: any) {
  const dispatch = useAppDispatch();
  const carPrices = useAppSelector((state) => state.carPrice.carPrices);
  const refresh = useAppSelector((state) => state.carPrice.refresh);
  const refreshLoader = useAppSelector((state) => state.carPrice.refreshLoader);
  const loading = useAppSelector((state) => state.carPrice.loading);
  const filters = useAppSelector((state) => state.carPrice.filters);
  const totalCount = useAppSelector((state) => state.carPrice.totalCount);
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE)
  );

  useEffect(() => {
    return () => {
      dispatch(carPriceActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    CarPriceService.getCarPrices(data);
  }, [filters, refresh, showFilters]);

  return (
    <>
      <TableContainer>
        {showFilters && <CarsPriceFilters />}
        <Box
          p={3}
          pt={0}
          bgcolor={"#ffffff"}
          borderRadius={"4px"}
          border={"1px solid #d3d3d3"}
        >
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              coloumns={7}
              loading={loading}
              length={carPrices.length < 1 && loading ? 0 : carPrices.length}
              message="No one has connected with our rental services yet"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Supplier</StyledTableCell>
                  <StyledTableCell>Daily Rate</StyledTableCell>
                  <StyledTableCell>Extra KM Cost</StyledTableCell>
                  <StyledTableCell>Free KM Per Day </StyledTableCell>
                  <StyledTableCell>Unlimited KM</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carPrices.map((carPrice, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{carPrice?.docNumber}</StyledTableCell>
                    <StyledTableCell>{carPrice?.name}</StyledTableCell>
                    <StyledTableCell>
                      {carPrice?.supplierId?.name} <br />{" "}
                      {carPrice?.supplierId?.mainContactPerson}
                    </StyledTableCell>
                    <StyledTableCell>
                      {carPrice?.dailyRate.toFixed(2)} SAR
                    </StyledTableCell>

                    <StyledTableCell>
                      {carPrice?.extraKMCost.toFixed(2)} SAR
                    </StyledTableCell>

                    <StyledTableCell>{carPrice?.freeKMPerDay}</StyledTableCell>

                    <StyledTableCell>
                      {carPrice?.isUnlimitedKM ? "Yes" : "No"}
                    </StyledTableCell>

                    <StyledTableCell>
                      <Chip status={carPrice?.currentStatus} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        className="detail-link"
                        to={`/car-price-details/${carPrice._id}`}
                      >
                        Details
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
          {!refreshLoader && showFilters && (
            <Stack alignItems={"center"}>
              <Pagination
                variant="outlined"
                shape="rounded"
                page={filters.page}
                count={Math.ceil(totalPages ?? filters.pageSize)}
                onChange={(_e, page) =>
                  dispatch(carPriceActions.setPage({ page }))
                }
              />
            </Stack>
          )}
        </Box>
      </TableContainer>
    </>
  );
}
