import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddCarModelForm from "./AddCarModelForm";
import UpdateCarModelForm from "./UpdateCarModelForm";
import CarModelService from "services/carModel.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { useLayoutEffect, useRef } from "react";
import { getFormValues } from "redux-form";

export default function AddCarModel() {
  const { id } = useParams();
  const navigate = useNavigate();
  const carModel = useAppSelector((state) => state.carModel.carModel);
  const loading = useAppSelector((state) => state.formLoader.loading);
  const formValues: any = useAppSelector((state) =>
    getFormValues("AddCarModelForm")(state)
  );

  const initialRef: any = useRef({});

  useLayoutEffect(() => {
    if (id && carModel) {
      const initialData = {
        name: carModel.name,
        manufacturer: carModel.manufacturer,
        carCategoryId: carModel.carCategoryId?._id,
        carImage: carModel.carImage,
        numberOfSeats: carModel.numberOfSeats,
        numberOfBags: carModel.numberOfBags,
        numberOfDoors: carModel.numberOfDoors,
        transmission: carModel.transmission,
        isAirConditioned: carModel.isAirConditioned,
        acrissCode: carModel.acrissCode,
      };
      initialRef.current = initialData;
    }
  }, [carModel, id]);

  const handleSubmit = async (values: any) => {
    let data = { ...values };

    data.numberOfBags = Number(values.numberOfBags);
    data.numberOfDoors = Number(values.numberOfDoors);
    data.numberOfSeats = Number(values.numberOfSeats);

    if (id) {
      const changes: any = {};
      Object.keys(formValues).forEach((key) => {
        if (formValues[key] !== initialRef.current[key]) {
          changes[key] = formValues[key];
        }
      });

      CarModelService.updateCarModel(`${id}`, changes, navigate);
    } else CarModelService.addCarModel(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${
            id && carModel ? `/car-model-details/${id}` : "/car-models"
          }`}
          title={`Back to ${id && carModel ? "Model Details" : "Car Models"} `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Model`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && carModel === "Not found" ? (
            <p>Car Model Not Found</p>
          ) : (
            <AddCarModelForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateCarModelForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
