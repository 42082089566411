import { useEffect } from "react";
import { Container, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./InsuranceDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import InsuranceService from "services/insurance.service";
import { insuranceActions } from "redux/slices/insurance";

const FieldBox = ({ label, children }: { label: string, children: React.ReactNode }) => (
  <div className={styles.fieldBox}>
    <label>{label}</label>
    <p>{children}</p>
  </div>
);

const InsuranceList = ({ items }: { items: string[] }) => (
  <ul>
    {items.map((item, index) => (
      <li key={index}>
        <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
          {item}
        </Typography>
      </li>
    ))}
  </ul>
);

const SimpleTable = ({ insurance }: { insurance: any }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell style={{ fontWeight: 'bold' }}>Daily Car Price</TableCell>
        <TableCell style={{ fontWeight: 'bold' }}>Daily Insurance Price</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <TableCell>0 to 100 SAR</TableCell>
        <TableCell>{insurance?.zero_to_one?.toFixed(2)} SAR</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>100 to 200 SAR</TableCell>
        <TableCell>{insurance?.one_to_two?.toFixed(2)} SAR</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>200 to 300 SAR</TableCell>
        <TableCell>{insurance?.two_to_three?.toFixed(2)} SAR</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>300 to 400 SAR</TableCell>
        <TableCell>{insurance?.three_to_four?.toFixed(2)} SAR</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>400 or greater</TableCell>
        <TableCell>{insurance?.more_than_four?.toFixed(2)} SAR</TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

export default function InsuranceDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const insurance = useAppSelector((state) => state.insurance.insurance);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    if (id) {
      InsuranceService.getInsurance(id);
    }
    return () => {
      dispatch(insuranceActions.setInsurance(null));
    };
  }, [id, dispatch]);

  // Early returns for loading and error handling
  if (loading) return <CircleLoader />;
  if (!id || !insurance) return <p>Insurance Not Found</p>;

  return (
    <Container maxWidth="xl">
      <GoBack path="/insurance" title="Back to Insurance" />
      <PageHeader title="Insurance details" />

      <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
        <Chip status={insurance?.currentStatus} />
        <Button
          variant="text"
          onClick={() => navigate(`/update-insurance/${insurance._id}`)}
        >
          Edit
        </Button>
      </Stack>

      <div className="form">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FieldBox label="IID">{insurance?.docNumber}</FieldBox>
            <FieldBox label="Name">{insurance?.name}</FieldBox>
            <FieldBox label="Description">{insurance?.description}</FieldBox>
            <FieldBox label="Info">{insurance?.info}</FieldBox>
            <FieldBox label="Excludes">
              <InsuranceList items={insurance?.excludes || []} />
            </FieldBox>
            <FieldBox label="Includes">
              <InsuranceList items={insurance?.includes || []} />
            </FieldBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleTable insurance={insurance} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
