import InputRedux from "components/molecules/InputRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { email, phone, required, requiredAutoComplete, requiredPhone } from "utils/validate.util";
import SelectCompany from "components/organisms/SelectCompany";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import SelectFranchise from "components/organisms/SelectFranchise";

export { default } from "./AddEmployeeForm";

export const fields: ReduxFormField[] = [
  {
    name: "firstName",
    label: "First Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "lastName",
    label: "Last Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "phone",
    label: "Phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "Email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "designation",
    label: "Designation",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "employeeNumber",
    label: "Employee Number",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "companyId",
    label: "Company",
    validate: [required],
    component: SelectCompany,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "franchiseId",
    label: "Franchise",
    validate: [required],
    component: SelectFranchise,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "address",
    label: "Address",
    validate: [required, requiredAutoComplete],
    component: AutoCompleteRedux,
    cellProps: { md: 12, lg: 12 },
  },
  {
    name: "description",
    label: "Description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12, lg: 12 },
    InputProps: { multiline: true, rows: 5 },
  },
];
