import { useEffect } from "react";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import UserService from "services/user.service";
import { userActions } from "redux/slices/user";

export default function SelectUser(props: any) {
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const userOptions = useAppSelector((state) => state.user.userOptions);

  useEffect(() => {
    let data = { page: 1, pageSize: 10000 };
    UserService.getUsers(data);

    return () => {
      dispatch(userActions.clear());
    };
  }, [dispatch]);

  return <SelectRedux {...props} options={userOptions} />;
}
