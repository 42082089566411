import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
} from "@mui/material";
import PromotionService from "services/promotion.service";
import { couponActions } from "redux/slices/coupon";
import SwitchCase from "components/atoms/SwitchCase";
import SubscriptionService from "services/subscription.service";
import CouponsService from "services/coupons.service";
import { MODAL, modalActions } from "redux/slices/modal";
import Button from "components/atoms/Button";

export default function CouponsList({ showFilters }: any) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const coupons = useAppSelector((state) => state.promotion.promotion);
  const loading = useAppSelector((state) => state.promotion.loading);
  const filters = useAppSelector((state) => state.promotion.filters);
  const refresh = useAppSelector((state) => state.promotion.refresh);
  const promotionId = id ?? '';

  useEffect(() => {
    PromotionService.getPromotion(promotionId);

    return () => {
      dispatch(couponActions.setCoupon(null));
    };

  }, [promotionId, filters, refresh, showFilters, dispatch]);

  const handleSubmit = (couponId: string) => {
    SubscriptionService.subscribe(() => { CouponsService.deleteCoupon(couponId, promotionId); });

    dispatch(
      modalActions.openModal({
        width: "500px",
        type: MODAL.CONFIRMATION_FORM,
        data: {
          id: couponId,
          heading: "Delete Coupon",
          message: `Do you really want to delete this coupon?`,
        },
      })
    );
  }

  return (
    <TableContainer sx={{ marginTop: "36px" }}>
      <Box
        p={3}
        pt={0}
        bgcolor={"#ffffff"}
        borderRadius={"4px"}
        border={"1px solid #d3d3d3"}
      >
        <Table
          aria-label="customized table"
          sx={{
            minWidth: "100%",
            borderSpacing: "0 10px",
            borderBottomWidth: "0px",
            borderCollapse: "separate",
          }}
        >
          <TableLoadingWrapper
            coloumns={6}
            loading={loading}
            length={coupons?.coupons?.length < 1 && loading ? 0 : coupons?.coupons?.length}
            message="No coupons to show"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Coupon Code</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coupons?.coupons?.map((coupon: any, index: any) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>{coupon?.code}</StyledTableCell>
                  <StyledTableCell>{coupon.type?.toUpperCase().replace('_', ' ')}</StyledTableCell>
                  <StyledTableCell>
                    <SwitchCase
                      checked={coupon.currentStatus === "active" ? true : false}
                      label={
                        coupon.currentStatus === "active" ? "Active" : "Inactive"
                      }
                      onChange={(e) => {
                        const checked = e.target.checked;
                        const currentStatus = checked ? "active" : "inactive";
                        dispatch(
                          SubscriptionService.subscribe(() => {
                            CouponsService.updateCouponStatus(coupon._id, promotionId, {
                              currentStatus,
                            });
                          })
                        )
                        // dispatch(
                        //   modalActions.openModal({
                        //     width: "500px",
                        //     type: MODAL.CONFIRMATION_FORM,
                        //     data: {
                        //       currentStatus,
                        //       id: coupon._id,
                        //       heading: "Update Coupon Status",
                        //       message: `Do you really want to make this coupon ${currentStatus}?`,
                        //     },
                        //   })
                        // );
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button className="detail-link" onClick={() => handleSubmit(coupon._id)}>Delete</Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </TableLoadingWrapper>
        </Table>
      </Box>
    </TableContainer >
  );
}
