import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import FranchiseService from "services/franchise.service";
import { franchiseActions } from "redux/slices/franchise";

export default function UpdateFranchiseForm({ id }: any) {
  const form = "AddFranchiseForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const franchise = useAppSelector((state) => state.franchise.franchise);

  useEffect(() => {
    FranchiseService.getFranchise(id || "");

    return () => {
      dispatch(franchiseActions.setFranchise(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!franchise) return;

    const {
      name,
      franchiseCode,
      companyId,
      address,
      phoneNumber,
      email,
      mainContactPerson,
      numberOfEmployees,
      dateOfEstablishment,
      description,
    } = franchise;

    dispatch(change(form, "name", name));
    dispatch(change(form, "franchiseCode", franchiseCode));
    dispatch(change(form, "companyId", companyId?._id));
    dispatch(
      change(form, "address", { value: address.formattedAddress, address })
    );
    dispatch(
      change(form, "phone", {
        value: phoneNumber?.dialingCode + phoneNumber.number,
      })
    );
    dispatch(change(form, "email", email));
    dispatch(change(form, "mainContactPerson", mainContactPerson));
    dispatch(change(form, "numberOfEmployees", numberOfEmployees));
    dispatch(
      change(form, "dateOfEstablishment", { date: dateOfEstablishment })
    );
    dispatch(change(form, "description", description));
  }, [franchise, navigate, dispatch]);

  return null;
}
