import { config } from "config";
import { SetCouponsPayload, CouponState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";
import { SetPagePayload, SetTotalCountPayload } from "../user";

const default_page_size = config.PAGE_SIZE;
const initialState: CouponState = {
  coupons: [],
  coupon: null,
  loading: true,
  current_filters: {},
  couponOptions: [],
  supplierCoupons: [],
  filters: { page: 1, pageSize: default_page_size },
  refresh: 0,
  refreshLoader: false,
  totalCount: 0,
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    clear: (state) => {
      state.couponOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addCoupon: (state, action) => {
      state.coupons.unshift(action.payload);
    },
    updateCouponStatus: (state, action) => {
      const { id, coupon } = action.payload;
      state.coupons.every(({ _id }, i) => {
        if (id === _id) {
          state.coupons[i] = coupon;
          return false;
        }
        return true;
      });
    },
    deleteCouponById: (state, action) => {
      const id = action.payload;
      state.coupons.every(({ _id }, i) => {
        if (id === _id) {
          state.totalCount -= 1;
          state.coupons.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setCoupon: (state, action) => {
      state.coupon = action.payload;
    },
    setCoupons: (state, action: PayloadAction<SetCouponsPayload>) => {
      const { coupons } = action.payload;
      let options: SelectOption[] = [];

      coupons.forEach(({ _id, modelId }: any) => {
        const option = { value: _id, label: `${modelId.name}` };
        options.push(option);
      });

      state.coupons = coupons;
      state.couponOptions = options;
    },

    setSupplierCoupons: (state, action: PayloadAction<SetCouponsPayload>) => {
      const { coupons } = action.payload;
      let options: SelectOption[] = [];
      coupons.forEach(({ modelId }: any) => {
        const option = { value: modelId._id, label: modelId.name };
        options.push(option);
      });
      state.supplierCoupons = options;
    },

    setTotalCount: (state, action: PayloadAction<SetTotalCountPayload>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
    },

    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const couponReducer = couponSlice.reducer;

export const couponActions = couponSlice.actions;
export default couponReducer;
