import { fields, priceFields } from ".";
import { Field, change, reduxForm } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import BranchService from "services/branch.service";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import SelectSupplier from "components/organisms/SelectSupplier";
import CarModelService from "services/carModel.service";
import { carModelActions } from "redux/slices/carModel";
import { required } from "utils/validate.util";
import SelectCarModel from "components/organisms/SelectCarModel";
import CarPriceService from "services/carPrice.service";
import { carPriceActions } from "redux/slices/carPrice";
import SelectRedux from "components/molecules/SelectRedux";

function AddCarForm({ handleSubmit }: any) {
  const dispatch = useAppDispatch();
  const car = useAppSelector((state) => state.car.car);
  const carModel = useAppSelector((state) => state.carModel.carModel);
  const carPrice = useAppSelector((state) => state.carPrice.carPrice);
  const branchesOptions = useAppSelector(
    (state) => state.branch.supplierBranches
  );

  const supplierId = useAppSelector(
    (state) => state.form?.["AddCarForm"]?.values?.supplierId
  );

  const carModelId = useAppSelector(
    (state) => state.form?.["AddCarForm"]?.values?.modelId
  );

  const carPricingId = useAppSelector(
    (state) => state.form?.["AddCarForm"]?.values?.carPricingId
  );

  useEffect(() => {
    if (supplierId) BranchService.getSupplierBranches(supplierId);
  }, [supplierId]);

  useEffect(() => {
    if (carModelId) CarModelService.getCarModel(carModelId);
  }, [carModelId]);

  useEffect(() => {
    if (carPricingId) CarPriceService.getCarPrice(carPricingId);
  }, [carPricingId]);

  useEffect(() => {
    if (car?.modelId?._id === carModel?._id) {
      return;
    } else if (carModel) {
      dispatch(
        change("AddCarForm", "isAirConditioned", carModel?.isAirConditioned)
      );
      dispatch(change("AddCarForm", "fuelType", carModel?.fuelType));
      dispatch(change("AddCarForm", "numberOfSeats", carModel?.numberOfSeats));
      dispatch(change("AddCarForm", "numberOfBags", carModel?.numberOfBags));
      dispatch(change("AddCarForm", "numberOfDoors", carModel?.numberOfDoors));
      dispatch(change("AddCarForm", "transmission", carModel?.transmission));
    }
    return () => {
      dispatch(carModelActions.setCarModel(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carModel]);

  useEffect(() => {
    if (carPrice) {
      dispatch(change("AddCarForm", "isUnlimitedKM", carPrice?.isUnlimitedKM));
      dispatch(change("AddCarForm", "name", carPrice?.name));
      dispatch(change("AddCarForm", "dailyRate", carPrice?.dailyRate));
      dispatch(change("AddCarForm", "weeklyRate", carPrice?.weeklyRate));
      dispatch(change("AddCarForm", "monthlyRate", carPrice?.monthlyRate));
      dispatch(change("AddCarForm", "extraKMCost", carPrice?.extraKMCost));
      dispatch(change("AddCarForm", "freeKMPerDay", carPrice?.freeKMPerDay));
    }
    return () => {
      dispatch(carPriceActions.setCarPrice(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carPrice]);

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} mb={2}>
          <Grid item md={12}>
            <Field
              name="supplierId"
              label="Supplier"
              component={SelectSupplier}
            />
          </Grid>
          <Grid item md={6}>
            <Field
              name="branchId"
              label="Branch"
              component={SelectRedux}
              options={branchesOptions}
              disabled={branchesOptions.length < 1}
            />
            {/* <Field
              name="branchId"
              label="Branch"
              component={ComboBoxRedux}
              multiple={false}
              options={branchesOptions}
              disabled={branchesOptions.length < 1}
              placeholder={
                branchesOptions.length < 1
                  ? "Select a supplier to get branches"
                  : "Select branches"
              }
            /> */}
          </Grid>
          <Grid item md={6}>
            <Field
              name="modelId"
              label="Car Model"
              validate={required}
              component={SelectCarModel}
            />
          </Grid>
          <Grid item md={12}>
            <ReduxFormFields fields={fields} />
          </Grid>

          <Grid item md={12}>
            <ReduxFormFields fields={priceFields} />
          </Grid>
        </Grid>

        <br />
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
}

export default reduxForm({ form: "AddCarForm" })(AddCarForm);
