import { required } from "utils/validate.util";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";

export { default } from "./AddCarCategoryForm";

export const fields: ReduxFormField[] = [
  {
    name: "name",
    label: "Category Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12 },
  },
  {
    name: "description",
    label: "Description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12, lg: 12 },
    InputProps: { multiline: true, rows: 5 },
  },
];
