import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { toggleNavigationIdAction, toggleSidebarAction } from 'redux/slices/sidebar/navigationSlice';

export const useNavigation = () => {
  const navigation = useAppSelector((state) => state.navigation.openNavigationIds);
  const isSidebarOpen = useAppSelector((state) => state.navigation.isSidebarOpen);
  const dispatch = useAppDispatch();

  const toggleNavigationId = useCallback((id: string) => dispatch(toggleNavigationIdAction(id)), [dispatch]);
  const getIsOpen = useCallback((id: string) => navigation.includes(id), [navigation]);
  const toggleSidebar = useCallback(() => dispatch(toggleSidebarAction(!isSidebarOpen)), [dispatch, isSidebarOpen]);

  return { getIsOpen, isSidebarOpen, toggleNavigationId, toggleSidebar };
};
