import http from "./http.service";
import Promisable from "./promisable.service";
import { userActions } from "redux/slices/user";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";

const url = "/api/user/comfy/admin";

const UserService = {
  getUser: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const user = success.data;
      dispatch?.(userActions.setUser(user));
    } else dispatch?.(userActions.setUser({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getUsers: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(userActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(userActions.setUsers({ documents }));
      dispatch?.(userActions.setTotalCount({ totalCount }));
    }

    dispatch?.(userActions.setLoading(false));
    return [success, error];
  },

  addUser: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();
    const formData = new FormData();

    formData.append("userId", data.userId);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("countryOfResidence", data.countryOfResidence);
    formData.append("roles", data.roles);
    formData.append("email", data.email);
    formData.append("phoneNumber", JSON.stringify(data.phoneNumber));
    formData.append("dateOfBirth", data.dateOfBirth);

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, formData)
    );

    if (success) {
      const user = success.data;
      dispatch?.(userActions.addUser(user));
      navigate?.("/users");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateUser: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const user = success.data;
      dispatch?.(userActions.setUser(user));
      navigate?.(`/user-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteUser: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(userActions.deleteUserById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default UserService;
