import InputRedux from "components/molecules/InputRedux";
import SelectRedux from "components/molecules/SelectRedux";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { isValidLat, isValidLng, number, required } from "utils/validate.util";
import ReduxFieldArray from "components/organisms/ReduxFieldArray";
import DateRangePickerRedux from "components/molecules/DateRangePickerRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";

export { default } from "./AddPromotionForm";

export const fields: ReduxFormField[] = [
  {
    name: "isNewUserOnly",
    label: "New User Only",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },
  {
    name: "isActiveEmailSpecific",
    label: "Emails Specific",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },

  {
    name: "isActiveModelSpecific",
    label: "Models Specific",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },
  {
    name: "isActiveLocationSpecific",
    label: "Location Specific",
    component: CheckboxRedux,
    cellProps: { md: 3 },
  },

  {
    name: "isActiveDateTimeSpecific",
    label: "Date & Time Specific",
    component: CheckboxRedux,
    cellProps: { md: 12 },
  },
  {
    name: "title",
    label: "Title",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "description",
    label: "Description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "minOrderAmount",
    label: "Minimum Order Amount",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "usageLimit",
    label: "Usage Limit",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "discountType",
    label: "Discount Type",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 4 },
    SelectProps: {
      options: [
        { value: "fixed", label: "Fixed" },
        { value: "percentage", label: "Percentage" },
      ],
    },
  },
  {
    name: "discountValue",
    label: "Discount Value",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4 },
  },
  {
    name: "emails",
    label: "Emails Specific",
    component: ComboBoxRedux,
    cellProps: { md: 12, lg: 12 },
    ComboBoxProps: {
      multiple: true,
      freeSolo: true,
      options: [],
    },
    InputProps: {
      type: "email",
      placeholder: "Enter email",
    },
  },
  {
    name: "modelSpecific",
    label: "Model Specific",
    component: ComboBoxRedux,
    cellProps: { md: 12, lg: 12 },
    ComboBoxProps: {
      multiple: true,
      freeSolo: true,
      options: [],
    },
    InputProps: {
      placeholder: "Enter Date",
    },
  },
  {
    name: "locationSpecific",
    label: "Location Specific",
    component: ReduxFieldArray,
    reduxFormComponent: "FieldArray",
    fieldsArray: [
      {
        name: "lat",
        label: "Latitude Address",
        validate: [number, isValidLat],
        component: InputRedux,
        cellProps: { md: 6 },
      },
      {
        name: "lng",
        label: "Longitude Address",
        validate: [number, isValidLng],
        component: InputRedux,
        cellProps: { md: 6 },
      },
      {
        name: "radiusInKm",
        label: "Searchable Radius (km)",
        validate: [number],
        component: InputRedux,
        cellProps: { md: 6 },
      },
    ],
  },

  {
    name: "dateTimeSpecific",
    label: "Date & Time Specific",
    component: ReduxFieldArray,
    reduxFormComponent: "FieldArray",
    fieldsArray: [
      {
        name: "startTime",
        label: "Start",
        component: DateRangePickerRedux,
        cellProps: { md: 12, lg: 12 },
        DatePickerProps: {
          onChange: () => {},
          minDate: new Date(),
          InputFieldProps: {},
          value: { date: "", error: false },
        },
      },
    ],
  },
];
