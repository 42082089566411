import { config } from "config";
import { SetCarModelsPayload, CarModelState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";
import { SetPagePayload } from "../user";

const default_page_size = config.PAGE_SIZE;
const initialState: CarModelState = {
  totalCount: 0,
  carModels: [],
  carModel: null,
  loading: true,
  refresh: 0,
  refreshLoader: false,
  current_filters: {},
  carModelOptions: [],
  filters: { page: 1, pageSize: default_page_size },
};

export const carModelSlice = createSlice({
  name: "carModel",
  initialState,
  reducers: {
    clear: (state) => {
      state.carModelOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addCarModel: (state, action) => {
      state.carModels.unshift(action.payload);
    },
    updateCarModel: (state, action) => {
      const { id, carModel } = action.payload;
      state.carModels.every(({ _id }, i) => {
        if (id === _id) {
          state.carModels[i] = carModel;
          return false;
        }
        return true;
      });
    },
    deleteCarModelById: (state, action) => {
      const id = action.payload;
      state.carModels.every(({ _id }, i) => {
        if (id === _id) {
          state.totalCount -= 1;
          state.carModels.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setCarModel: (state, action) => {
      state.carModel = action.payload;
    },
    setCarModels: (state, action: PayloadAction<SetCarModelsPayload>) => {
      const { totalCount, carModels } = action.payload;
      let options: SelectOption[] = [];

      carModels.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });

      state.totalCount = totalCount;
      state.carModels = carModels;
      state.carModelOptions = options;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const carModelReducer = carModelSlice.reducer;

export const carModelActions = carModelSlice.actions;
export default carModelReducer;
