import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import SupplierService from "services/supplier.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import AddSupplierForm from "./AddSupplierForm";
import UpdateSupplierForm from "./UpdateSupplierForm";
import { phoneFormat } from "utils/phone.util";

export default function AddSupplier() {
  const { id } = useParams();
  const navigate = useNavigate();
  const supplier = useAppSelector((state) => state.supplier.supplier);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = async (values: any) => {
    let data = { ...values };
    data.address = values.address.address;
    data.numberOfVehicles = Number(values.numberOfVehicles);
    data.phoneNumber = phoneFormat(
      values?.phone?.data?.countryCode ? values?.phone : supplier?.phoneNumber
    );

    delete data.phone;

    if (id) {
      if (values.address?.address?._id) {
        const { _id, ...addressWithoutId } = values.address.address;
        data.address = addressWithoutId;
      }

      SupplierService.updateSupplier(`${id}`, data, navigate);
    } else SupplierService.addSupplier(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && supplier ? `/supplier-details/${id}` : "/suppliers"}`}
          title={`Back to ${
            id && supplier ? "Supplier Details" : "Suppliers"
          } `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Supplier`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && supplier === "Not found" ? (
            <p>Supplier Not Found</p>
          ) : (
            <AddSupplierForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateSupplierForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
