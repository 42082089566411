import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { carPriceActions } from "redux/slices/carPrice";

const url = "/api/non-api/car-pricing";

const CarPriceService = {
  getCarPrice: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const carPrice = success.data;
      dispatch?.(carPriceActions.setCarPrice(carPrice));
    } else dispatch?.(carPriceActions.setCarPrice({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCarPrices: async (data: any = {}) => {
    const dispatch = getAppDispatch();
    dispatch?.(carPriceActions.setLoading(true));

    http.setJWT();

    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(
        carPriceActions.setCarPrices({ carPrices: documents, totalCount })
      );
    }

    dispatch?.(carPriceActions.setLoading(false));
    return [success, error];
  },

  addCarPrice: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const carPrice = success.data;
      dispatch?.(carPriceActions.addCarPrice(carPrice));
      navigate?.("/car-prices");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCarPrice: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const carPrice = success.data;
      dispatch?.(carPriceActions.setCarPrice(carPrice));
      navigate?.(`/car-price-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteCarPrice: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(carPriceActions.deleteCarPriceById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CarPriceService;
