import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import AddPromotionForm from "./AddPromotionForm";
import UpdatePromotionForm from "./UpdatePromotionForm";
import PromotionService from "services/promotion.service";

export default function AddPromotion() {
  const { id } = useParams();
  const navigate = useNavigate();
  const promotion = useAppSelector((state) => state.promotion.promotion);

  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    const {
      title,
      description,
      discountType,
      discountValue,
      usageLimit,
      minOrderAmount,
      emailSpecific,
      locationSpecific,
      modelSpecific,
      dateTimeSpecific,
      emails,
      isActiveEmailSpecific,
      isActiveModelSpecific,
      isActiveLocationSpecific,
      isActiveDateTimeSpecific,
    } = values;

    const data = {
      title,
      description,
      discountType,
      discountValue: +discountValue,
      usageLimit: +usageLimit,
      minOrderAmount: +minOrderAmount,
      emailSpecific,
      locationSpecific,
      modelSpecific,
      dateTimeSpecific,
    };

    data.emailSpecific = {
      emails: emails ?? [],
      isActive: isActiveEmailSpecific ?? false,
    };
    data.modelSpecific = {
      models: modelSpecific ?? [],
      isActive: isActiveModelSpecific ?? false,
    };
    data.locationSpecific = {
      locations:
        data.locationSpecific.map(({ _id, ...rest }: any) => {
          return {
            ...rest,
            lat: +rest.lat || 0,
            lng: +rest.lng || 0,
            radiusInKm: +rest.radiusInKm || 0,
          };
        }) ?? [],
      isActive: isActiveLocationSpecific ?? false,
    };

    data.dateTimeSpecific = {
      dates:
        data.dateTimeSpecific.map((item: any) => ({
          startTime: new Date(item.startTime.date[0]),
          endTime: new Date(item.startTime.date[1]),
        })) ?? [],
      isActive: isActiveDateTimeSpecific ?? false,
    };

    if (id) {
      PromotionService.updatePromotion(`${id}`, data, navigate);
    } else PromotionService.addPromotion(data, navigate);
  };

  return (
    <Container maxWidth="xl">
      <GoBack
        path={`${
          id && promotion !== "Not Found"
            ? `/promotion-details/${id}`
            : "/promotions"
        }`}
        title={`Back to ${
          id && promotion !== "Not Found" ? "Promotion Details" : "Promotions"
        } `}
      />
      <PageHeader title={`${id ? "Update" : "Add"} Promotion`} />
      <div className="form">
        {loading && <CircleLoader />}
        {id && !promotion ? (
          <p>Promotion Not Found</p>
        ) : (
          <AddPromotionForm onSubmit={handleSubmit} />
        )}
        {id && <UpdatePromotionForm id={id} />}
      </div>
    </Container>
  );
}
