import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { employeeActions } from "redux/slices/employee";

const url = "/api/b2b/employee";

const EmployeeService = {
  getEmployee: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const employee = success.data;
      dispatch?.(employeeActions.setEmployee(employee));
    } else dispatch?.(employeeActions.setEmployee({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getEmployees: async (data: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(employeeActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(employeeActions.setEmployees({ documents }));
      dispatch?.(employeeActions.setTotalCount({ totalCount }))
    }

    dispatch?.(employeeActions.setLoading(false));
    return [success, error];
  },

  addEmployee: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const employee = success.data;
      dispatch?.(employeeActions.addEmployee(employee));
      navigate?.("/employees");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateEmployee: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const employee = success.data;
      dispatch?.(employeeActions.setEmployee(employee));
      navigate?.(`/employee-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteEmployee: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(employeeActions.deleteEmployeeById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default EmployeeService;
