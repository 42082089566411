import CheckboxRedux from "components/molecules/CheckboxRedux";
import DateRangePickerRedux from "components/molecules/DateRangePickerRedux";
import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields";
import TimePickerRedux from "components/organisms/TimePickerRedux";
import { date, dateRange } from "utils/validate.util";

export { default } from "./UpdateBookingForm";

export const fields: ReduxFormField[] = [
  {
    name: "isPaid",
    label: "Paid",
    component: CheckboxRedux,
    cellProps: { md: 3, lg: 3 },
    CheckBoxProps: { defaultChecked: true },
  },
  {
    name: "adminTotalPrice",
    label: "Custom price",
    component: InputRedux,
    cellProps: { md: 9, lg: 9 },
  },
];
