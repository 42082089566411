import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { promotionActions } from "redux/slices/promotion";

export default function PromotionFilters() {
  const form = "PromotionFiltersForm";
  const dispatch = useAppDispatch();

  let data = {};
  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    for (const key of Object.keys(values)) {
      data = { ...data, [key]: values[key] };
    }

    dispatch(
      promotionActions.setFilters({
        ...data,
        page: 1,
        pageSize: default_page_size,
      })
    );
  };
  const formFields = [
    "emailSpecific",
    "locationSpecific",
    "modelSpecific",
    "dateTimeSpecific",
    "isNewUserOnly",
    "docNumber",
    "title",
    "discountType",
    "discountValue",
    "minOrderLimit",
    "pageSize",
  ];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(promotionActions.resetFilters())}
        totalCountSelector={(state: any) => state.promotion.totalCount}
      />
    </div>
  );
}
