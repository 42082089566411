import { styled } from "@mui/system";
import { TableRow, TableCell, tableCellClasses } from "@mui/material";

export { default } from "./Tables";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  td: { padding: "12px 16px 12px 0px" ,border: 0, borderBottom: "1px dashed #cdcdcd" },
  background: "#ffffff",
  "&:last-child td": {
    borderBottom: 0,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // paddingTop: "0",
	paddingLeft: 0,
    color: "#ed1c24",
    fontWeight: "600",
    // paddingBottom: "0",
    // borderBottomWidth: "0px",
    background: "#ffffff",
    textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "rgb(0 0 0 / 70%)",
  },
}));
