import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { carCategoryActions } from "redux/slices/carCategory";

export default function CarCategoryFilters() {
  const form = "CarCategoryFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.name) data.name = values.name;

    if (values.pageSize) data.pageSize = values.pageSize;
    dispatch(carCategoryActions.setFilters(data));
  };
  const formFields = ["docNumber", "name", "pageSize"];
  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(carCategoryActions.resetFilters())}
        totalCountSelector={(state: any) => state.carCategory.totalCount}
      />
    </div>
  );
}
