import { useEffect } from "react";
import { Chip as MUIChip, Container, Grid, Stack } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./CarPriceDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import CarPriceService from "services/carPrice.service";
import { carPriceActions } from "redux/slices/carPrice";

export default function CarPriceDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carPrice = useAppSelector((state) => state.carPrice.carPrice);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    CarPriceService.getCarPrice(id || "");
    return () => {
      dispatch(carPriceActions.setCarPrice(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/car-prices" title="Back to Car Prices" />
      <PageHeader title="Car Price details" />
      {loading && <CircleLoader />}
      {!loading && !carPrice ? (
        <p>Car Price Not Found</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={carPrice?.currentStatus} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-car-price/${carPrice._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <Grid container>
            <Grid item md={6} lg={3}>
              <div className={styles.fieldBox}>
                <label htmlFor=""> ID</label>
                <p>{carPrice?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Name</label>
                <p>{carPrice?.name}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Supplier</label>
                <br />
                <Link
                  to={`/supplier-details/${carPrice?.supplierId?._id}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {carPrice?.supplierId?.name}
                </Link>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Daily Rate:</label>
                <p>{carPrice?.dailyRate?.toFixed(2)} SAR</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Extra KM Cost:</label>
                <p>{carPrice?.extraKMCost?.toFixed(2)} SAR</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Unlimited KM:</label>
                <p>{carPrice?.isUnlimitedKM ? "Yes" : "No"}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Free KM Per Day:</label>
                <p>{carPrice?.freeKMPerDay}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}
