import { Grid, Typography } from "@mui/material";
import RadioGroupRedux from "components/molecules/RadioGroupRedux";
import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { useAppSelector } from "redux/hooks";

const UpdateBookingInsuranceForm = () =>
  // { handleSubmit, initialize }: any
  {
    const booking = useAppSelector((state) => state.booking.booking);
    const extras = useAppSelector((state) => state.addBooking.extras);

    // useEffect(() => {
    //   if (extras?.insurances?.length > 0) {
    //     // Initialize with the selected insurance ID, if available in the booking
    //     const selectedInsuranceId = booking?.insurances?.[0]?.id;
    //     if (selectedInsuranceId) {
    //       // Initialize the form with the selected insurance's ID
    //       initialize({ insurance: selectedInsuranceId });
    //     }
    //   }
    // }, [booking, extras, initialize]);

    return (
      <Grid item md={6}>
        {/* <form onSubmit={handleSubmit}> */}
        {extras && extras?.insurances?.length > 0 && (
          <div className="add-booking-extras">
            <Typography fontWeight={600}>Insurances</Typography>
            <Field
              name="insurance"
              component={RadioGroupRedux}
              values={extras.insurances.map((ins: any) => ({
                value: ins.id,
                label: `${ins.name} (${ins.totalAmount.toFixed(2)} SAR)`,
              }))}
            />
          </div>
        )}
        {/* </form> */}
      </Grid>
    );
  };

export default // reduxForm({ form: "UpdateBookingInsuranceForm" })(
UpdateBookingInsuranceForm;
// );
