import List from "@mui/material/List";
import { NavigationItem } from "./components/navigation-item/NavigationItem";
import { NavigationItemType } from "./components/navigation-item/types";
import {
  AirportShuttleOutlined,
  FactCheckOutlined,
  AssignmentIndOutlined,
  AccountTreeOutlined,
  AttributionOutlined,
  TimeToLeaveOutlined,
  GarageOutlined,
  ElectricCarOutlined,
  MinorCrashOutlined,
  HealthAndSafetyOutlined,
} from "@mui/icons-material";
import UpcomingOutlinedIcon from "@mui/icons-material/UpcomingOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import MoreOutlinedIcon from "@mui/icons-material/MoreOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { IoCarSportOutline } from "react-icons/io5";
import { useMemo } from "react";

export function Navigation() {
  const navigationItems: NavigationItemType[] = useMemo(
    () => [
      {
        path: "/dashboard",
        label: "Dashboard",
        icon: (props: any) => <DashboardOutlinedIcon {...props} />,
      },
      {
        label: "Bookings",
        icon: (props: any) => <UpcomingOutlinedIcon {...props} />,
        description: "Bookings history",
        items: [
          {
            path: "/search-fleet",
            label: "Add Booking",
            icon: (props: any) => <AddBoxOutlinedIcon {...props} />,
          },
          {
            path: "/upcoming-bookings",
            label: "Upcoming",
            icon: (props: any) => <UpcomingOutlinedIcon {...props} />,
          },
          {
            path: "/ongoing-bookings",
            label: "On Going",
            icon: (props: any) => <AirportShuttleOutlined {...props} />,
          },
          {
            path: "/past-bookings",
            label: "Past",
            icon: (props: any) => <FactCheckOutlined {...props} />,
          },
          {
            path: "/bookings",
            label: "All Orders",
            icon: (props: any) => <IoCarSportOutline {...props} />,
          },
        ],
      },

      {
        label: "Users",
        icon: (props: any) => <AssignmentIndOutlined {...props} />,
        description: "Users management",
        items: [
          {
            path: "/users",
            label: "Users",
          },
          {
            path: "/add-user",
            label: "Add User",
          },
        ],
      },

      // {
      //   header: "B2B Management",
      // },
      {
        label: "B2B Management",
        icon: (props: any) => <EmojiTransportationOutlinedIcon {...props} />,
        description: "Manage companies",
        items: [
          {
            path: "/companies",
            label: "Companies",
          },
          {
            path: "/franchises",
            label: "Franchises",
          },
          {
            path: "/employees",
            label: "Employees",
          },
        ],
      },
      // {
      //   header: "Non-API Providers",
      // },
      {
        // path: "/suppliers",
        label: "Non-API Providers",
        icon: (props: any) => <AttributionOutlined {...props} />,
        description: "Manage suppliers",
        items: [
          {
            path: "/suppliers",
            label: "Suppliers",
          },
          {
            path: "/branches",
            label: "Branch",
          },
          {
            path: "/cars",
            label: "Cars",
          },
          {
            path: "/car-prices",
            label: "Car Pricing",
          },
          {
            path: "/car-extras",
            label: "Car Extras",
          },
          {
            path: "/car-categories",
            label: "Car Categories",
          },
          {
            path: "/car-models",
            label: "Car Models",
          },
        ],
      },

      {
        // path: "/car-specs",
        label: "Yelo Specifications",
        icon: (props: any) => <MinorCrashOutlined {...props} />,
        description: "Manage yelo specs",
        items: [
          {
            path: "/car-specs",
            label: "Car Specs",
          },
          {
            path: "/add-car-specs",
            label: "Add Car Specs",
          },
        ],
      },
      {
        label: "Insurance Packages",
        icon: (props: any) => <HealthAndSafetyOutlined {...props} />,
        description: "Insurance plans",
        items: [
          {
            path: "/insurance",
            label: "Insurance",
          },
          {
            path: "/add-insurance",
            label: "Add Insurance",
          },
        ],
      },
      {
        // path: "/promotions",
        label: "Promotions",
        icon: (props: any) => <GarageOutlined {...props} />,
        description: "Coupon & Campaigns",
        items: [
          {
            path: "/promotions",
            label: "Promotions",
          },
          {
            path: "/add-promotion",
            label: "Add Promotion",
          },
        ],
      },

      // {
      //   header: "Settings",
      // },
      {
        // path: "/price-engine",
        label: "Price Engine",
        icon: (props: any) => <CalculateOutlinedIcon {...props} />,
        description: "Pricing plans",
        items: [
          {
            path: "/price-engine",
            label: "Price Engine",
          },
          {
            path: "/add-price",
            label: "Add Price",
          },
        ],
      },
      {
        path: "/tickets",
        label: "Support Tickets",
        icon: (props: any) => <SupportAgentOutlinedIcon {...props} />,
      },
    ],
    []
  );

  const navigationItemsList = navigationItems.map((item) => {
    return <NavigationItem key={Object.values(item).toString()} item={item} />;
  });

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, padding: 2 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {navigationItemsList}
    </List>
  );
}
