import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { carPriceActions } from "redux/slices/carPrice";

export default function CarsPriceFilters() {
  const form = "CarsPriceFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.isAirConditioned)
      data.isAirConditioned = values.isAirConditioned;
    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.name) data.name = values.name;
    if (values.dailyRate) data.dailyRate = values.dailyRate;
    if (values.weeklyRate) data.weeklyRate = values.weeklyRate;
    if (values.monthlyRate) data.monthlyRate = values.monthlyRate;
    if (values.extraKMCost) data.extraKMCost = values.extraKMCost;
    if (values.freeKMPerDay) data.freeKMPerDay = values.freeKMPerDay;
    if (values.isUnlimitedKM) data.isUnlimitedKM = values.isUnlimitedKM;
    if (values.pageSize) data.pageSize = values.pageSize;

    dispatch(carPriceActions.setFilters(data));
  };
  const formFields = [
    "isUnlimitedKM",
    "docNumber",
    "name",
    "dailyRate",
    "weeklyRate",
    "monthlyRate",
    "extraKMCost",
    "freeKMPerDay",
    "pageSize",
  ];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(carPriceActions.resetFilters())}
        totalCountSelector={(state: any) => state.carPrice.totalCount}
      />
    </div>
  );
}
