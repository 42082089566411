import { useEffect } from "react";
import BranchService from "services/branch.service";
import { branchActions } from "redux/slices/branch";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function SelectBranch(props: any) {
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const branchOptions = useAppSelector((state) => state.branch.branchOptions);
  const supplierId = useAppSelector(
    (state) => state.form?.[props?.form]?.values?.supplierId
  );
  const supplierBranches = useAppSelector(
    (state) => state.branch.supplierBranches
  );
  const loading = useAppSelector((state) => state.branch.loading);

  useEffect(() => {
    if (supplierId) {
      BranchService.getSupplierBranches(supplierId);
    } else {
      BranchService.getBranches({});
    }
    return () => {
      dispatch(branchActions.clear());
    };
  }, [dispatch, supplierId]);

  return (
    <SelectRedux
      {...props}
      options={supplierId ? supplierBranches : branchOptions}
      disabled={loading ? true : false}
    />
  );
}
