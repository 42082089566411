import CarsList from "./CarsList";
import { Container } from "@mui/material";
import Button from "components/atoms/Button";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { useNavigate } from "react-router-dom";

export default function Cars() {
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="xl">
        <PageHeader
          title="Cars"
          renderRight={
            <Button
              variant="contained"
              onClick={() => navigate("/add-sup-car")}
            >
              Add Car
            </Button>
          }
        />
        <CarsList showFilters />
      </Container>
    </div>
  );
}
