import { useEffect } from "react";
import { supplierActions } from "redux/slices/supplier";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarCategoryService from "services/carCategory.service";

export default function SelectCarCategory(props: any) {
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const carCategories = useAppSelector(
    (state) => state.carCategory.carCategoryOptions
  );

  useEffect(() => {
    CarCategoryService.getCarCategories();

    return () => {
      dispatch(supplierActions.clear());
    };
  }, [dispatch]);

  return (
    <SelectRedux
      {...props}
      options={carCategories}
      // disabled={id ? true : false}
    />
  );
}
