import { fields } from ".";
import { Field, reduxForm, reset } from "redux-form";
import Button from "components/atoms/Button";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { useAppSelector } from "redux/hooks";
import { Grid } from "@mui/material";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import InputRedux from "components/molecules/InputRedux";
import { required } from "utils/validate.util";
import { useParams } from "react-router-dom";
import CouponsService from "services/coupons.service";

function AddCouponsForm({ handleSubmit, reset }: any) {
  const { id } = useParams();
  const values = useAppSelector((state) => state.form?.["AddCouponsForm"]?.values);
  const promotionId = id;

  let data = { ...values };

  data.numberOfCoupons = Number(values?.numberOfCoupons);
  data.promotionId = promotionId;


  const handleAddCoupon = (e: React.FormEvent) => {
    e.preventDefault();
    CouponsService.addCouponCode(data, `${id}`);
    reset("AddCouponsForm")
  };

  const handleGenerateCoupon = (e: React.FormEvent) => {
    e.preventDefault();
    CouponsService.addCouponAuto(data, `${id}`);
    reset("AddCouponsForm")
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <PageHeader title={"Add Coupons"} />
        <ReduxFormFields fields={fields} />

        <Grid container spacing={2} mb={2} mt={0.5}>
          <Grid item md={6} sx={{ display: 'flex', alignItems: 'start' }}>
            <Field
              name="code"
              label="Coupon"
              validate={values?.numberOfCoupons ? [] : [required]}
              component={InputRedux}
              InputProps={{ disabled: values?.numberOfCoupons }}
            />
            <Button variant="contained" type="submit" sx={{ textTransform: 'none', borderRadius: '0 4px 4px 0', padding: '10px 12px', marginLeft: "-2px", marginTop: "20px" }} onClick={handleAddCoupon}>Add</Button>
          </Grid>
          <Grid item md={6} sx={{ display: 'flex', alignItems: 'start' }}>
            <Field
              name="numberOfCoupons"
              label="Coupon Count"
              validate={values?.code ? [] : [required]}
              component={InputRedux}
              InputProps={{ disabled: values?.code }}
            />
            <Button variant="contained" type="submit" sx={{ textTransform: 'none', borderRadius: '0 4px 4px 0', padding: '10px 12px', marginLeft: "-2px", marginTop: "20px" }} onClick={handleGenerateCoupon}>Generate</Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default reduxForm({ form: "AddCouponsForm" })(AddCouponsForm);
