import InputRedux from "components/molecules/InputRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import {
  email,
  number,
  phone,
  required,
  requiredAutoComplete,
  requiredPhone,
} from "utils/validate.util";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import FileUploadRedux from "components/molecules/FileUploadRedux";

export { default } from "./AddSupplierForm";

export const fields: ReduxFormField[] = [
  {
    name: "name",
    label: "Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "legalName",
    label: "Legal Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "mainContactPerson",
    label: "Contact Person Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "numberOfVehicles",
    label: "Number Of Vehicles",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "taxId",
    label: "Tax ID",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "registrationNumber",
    label: "Registration Number",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "phone",
    label: "Phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "Email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "businessLogo",
    label: "Business Logo",
    validate: [required],
    component: FileUploadRedux,
    cellProps: { md: 12, lg: 12 },
  },
  {
    name: "address",
    label: "Address",
    validate: [required, requiredAutoComplete],
    component: AutoCompleteRedux,
    cellProps: { md: 12, lg: 12 },
  },
  {
    name: "description",
    label: "Description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12, lg: 12 },
    InputProps: { multiline: true, rows: 5 },
  },
];
