import {
  UserMenuContainer,
  UserMenuIconButton,
  UserMenuInfo,
  UserMenuMenu,
  UserMenuMenuItem,
  UserMenuMenuItemWithSeparator,
  UserNameIconButton,
} from './styled';
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useAppSelector } from 'redux/hooks';
import AuthService from 'services/auth.service';
import { useNavigate } from 'react-router-dom';
import { SettingsOutlined } from '@mui/icons-material';

export const UserMenu = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <UserMenuContainer>
      <UserMenuIconButton onClick={handleClick}>
        <UserNameIconButton>{user.firstName.charAt() + user.lastName.charAt()}</UserNameIconButton>
        <SettingsOutlined />
      </UserMenuIconButton>
      <UserMenuMenu
        id='user-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <UserMenuInfo>
          <Typography fontSize={14} color={'text.secondary'}>
            <span style={{ textTransform: "capitalize" }}>{user.firstName + " " + user.lastName}</span>
            <br />
            {user.email}
          </Typography>
        </UserMenuInfo>
        <UserMenuMenuItem onClick={() => navigate("/profile")}>Profile</UserMenuMenuItem>
        <UserMenuMenuItemWithSeparator onClick={() => AuthService.logout()}>Logout</UserMenuMenuItemWithSeparator>
      </UserMenuMenu>
    </UserMenuContainer>
  );
};
