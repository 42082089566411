import Container from "@mui/material/Container";
import AddCouponsForm from "./AddCouponsForm";

export default function AddCoupons() {
  return (
    <div>
      <Container maxWidth="xl">
        <div className="form">
          <AddCouponsForm />
        </div>
      </Container>
    </div>
  );
}
