import InputRedux from "components/molecules/InputRedux";
import SelectRedux from "components/molecules/SelectRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { email, phone, required, requiredPhone } from "utils/validate.util";
import DatePickerRedux from "components/molecules/DatePickerRedux";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";

export { default } from "./AddUserForm";

export enum E_ROLES {
  COMFY_ADMIN = 'comfy_admin',
  COMFY_SALES_MANAGER = 'comfy_sales_manager',
  COMFY_OPERATIONS_MANAGER = 'comfy_operations_manager',
  SUPPLIER_ADMIN = 'supplier_admin',
  SUPPLIER_BRANCH_MANAGER = 'supplier_branch_manager',
  COMPANY_ADMIN = 'company_admin',
  COMPANY_BRANCH_MANAGER = 'company_branch_manager',
  USER = 'user',
}

export const fields: ReduxFormField[] = [
  {
    name: "firstName",
    label: "First Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "lastName",
    label: "Last Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "phone",
    label: "Phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "Email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "nationality",
    label: "Nationality",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "countryOfResidence",
    label: "Country Of Residence",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "dateOfBirth",
    label: "Date of Birth",
    validate: [required],
    component: DatePickerRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "gender",
    label: "Gender",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 6, lg: 6 },
    SelectProps: {
      options: [
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        // { value: "other", label: "Other" },
      ],
    },
  },
  {
    name: "roles",
    label: "Roles",
    validate: [required],
    component: ComboBoxRedux,
    cellProps: { md: 6, lg: 6 },
    ComboBoxProps: {
      multiple: true,
      freeSolo: false,
      options: [
        { label: "User", value: E_ROLES.USER },
        { label: "Sales Manager", value: E_ROLES.COMFY_SALES_MANAGER },
        { label: "Operations Manager", value: E_ROLES.COMFY_OPERATIONS_MANAGER },
        { label: "Supplier Admin", value: E_ROLES.SUPPLIER_ADMIN },
        { label: "Supplier Branch Manager", value: E_ROLES.SUPPLIER_BRANCH_MANAGER },
        { label: "Company Admin", value: E_ROLES.COMPANY_ADMIN },
        { label: "Company Branch Manager", value: E_ROLES.COMPANY_BRANCH_MANAGER },
      ],
    },
  },
];
