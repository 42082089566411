import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { employeeActions } from "redux/slices/employee";

export default function EmployeesFilters() {
  const form = "EmployeesFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.employeeNumber) data.employeeNumber = values.employeeNumber;
    if (values.firstName) data.firstName = values.firstName;
    if (values.lastName) data.lastName = values.lastName;
    if (values.email) data.email = values.email;
    if (values.phoneNumber) data.phoneNumber = values.phoneNumber.value;
    if (values.designation) data.designation = values.designation;
    if (values.companyId) data.companyId = values.companyId;
    if (values.franchiseId) data.franchiseId = values.franchiseId;
    if (values.pageSize) data.pageSize = values.pageSize;
    dispatch(employeeActions.setFilters(data));
  };
  const formFields = [
    "docNumber",
    "employeeNumber",
    "firstName",
    "lastName",
    "email",
    "phoneNumber",
    "designation",
    "companyId",
    "franchiseId",
    "pageSize",
  ];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(employeeActions.resetFilters())}
        totalCountSelector={(state: any) => state.employee.totalCount}
      />
    </div>
  );
}
