import { useEffect } from "react";
import { Container, Stack, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./BranchDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import BranchService from "services/branch.service";
import { branchActions } from "redux/slices/branch";

export default function BranchDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const branch = useAppSelector((state) => state.branch.branch);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    BranchService.getBranch(id || "");
    return () => {
      dispatch(branchActions.setBranch(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/branches" title="Back to Branches" />
      <PageHeader title="Branch details" />
      {loading && <CircleLoader />}
      {id && !loading && !branch ? (
        <p>Branch Not Found</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={branch?.currentStatus} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-branch/${branch._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <Grid container>
            <Grid item md={6}>
              <div className={styles.fieldBox}>
                <label htmlFor="">Branch Id</label>
                <p>{branch?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Name</label>
                <p>{branch?.name}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Branch Code</label>
                <p>{branch?.branchCode}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Phone</label>
                <p>
                  +{branch?.phoneNumber?.dialingCode}
                  {branch?.phoneNumber.number}
                </p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Email</label>
                <p>{branch?.email}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Address</label>
                <p>{branch?.address?.formattedAddress}</p>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={styles.fieldBox}>
                <h4 style={{ marginTop: 0 }}>Working Hours</h4>
                {branch?.workingHours?.map((workingHour: any) => (
                  <Grid container>
                    <Grid item md={6}>
                      <label style={{ textTransform: "capitalize" }}>
                        {workingHour?.day}{" "}
                      </label>
                    </Grid>
                    <Grid item md={6}>
                      <p>
                        {workingHour?.is24Hours
                          ? "24 Hours"
                          : workingHour?.isHoliday
                          ? "Holiday"
                          : `${workingHour.startTime} - ${workingHour.endTime}`}
                      </p>
                    </Grid>
                  </Grid>
                ))}
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Container>
  );
}
