import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { branchActions } from "redux/slices/branch";

export default function BranchesFilters() {
  const form = "BranchesFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.name) data.name = values.name;
    if (values.branchCode) data.branchCode = values.branchCode;
    if (values.supplierId) data.supplierId = values.supplierId;
    if (values.email) data.email = values.email;
    if (values.phoneNumber) data.phoneNumber = values.phoneNumber.value;
    if (values.pageSize) data.pageSize = values.pageSize;

    dispatch(branchActions.setFilters(data));
  };
  const formFields = [
    "docNumber",
    "name",
    "branchCode",
    "supplierId",
    "email",
    "phoneNumber",
    "pageSize",
  ];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(branchActions.resetFilters())}
        totalCountSelector={(state: any) => state.branch.totalCount}
      />
    </div>
  );
}
