import React, { useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import { Field, reduxForm } from "redux-form";
import { Grid, Typography } from "@mui/material";
import CheckboxRedux from "components/molecules/CheckboxRedux";

const UpdateBookingExtrasForm = (
  // { handleSubmit, initialize }: any
) => {
  const booking = useAppSelector((state) => state.booking.booking);
  const extras = useAppSelector((state) => state.addBooking.extras);

  // useEffect(() => {
  //   if (extras?.extras?.length > 0) {
  //     const initialValues = extras.extras.reduce((acc: any, extra: any) => {
  //       const isSelected = booking?.extras?.some(
  //         (bExtra: any) => bExtra.id === extra.id
  //       );
  //       acc[extra.id] = isSelected;
  //       return acc;
  //     }, {});

  //     initialize(initialValues);
  //   }
  // }, [booking, extras, initialize]);
  
  if (extras?.extras?.length > 0)
    return (
      <Grid item md={6}>
        {/* <form onSubmit={handleSubmit}> */}
        {extras && extras?.extras?.length > 0 && (
          <div className="add-booking-extras">
            <Typography fontWeight={600}>Extras</Typography>
            {extras?.extras?.map((extra: any, index: any) => (
              <div>
                <Field
                  key={index}
                  component={CheckboxRedux}
                  name={extra?.id}
                  label={`${extra.name} (${extra.totalAmount.toFixed(2)} SAR)`}
                />
              </div>
            ))}
          </div>
        )}
        {/* </form> */}
      </Grid>
    );
  else return <></>;
};

export default // reduxForm({ form: "UpdateBookingExtrasForm" })(
UpdateBookingExtrasForm;
// );
