import { useEffect } from "react";
import { supplierActions } from "redux/slices/supplier";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarPriceService from "services/carPrice.service";

export default function SelectCarPrice(props: any) {
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const carPrices = useAppSelector(
    (state) => state.carPrice.carPriceOptions
  );

  useEffect(() => {
    CarPriceService.getCarPrices();

    return () => {
      dispatch(supplierActions.clear());
    };
  }, [dispatch]);

  return (
    <SelectRedux
      {...props}
      options={carPrices}
    // disabled={id ? true : false}
    />
  );
}
