import { useEffect } from "react";
import { supplierActions } from "redux/slices/supplier";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch } from "redux/hooks";
import CarModelService from "services/carModel.service";

export default function SelectExtraName(props: any) {
  const dispatch = useAppDispatch();

  const extraName = [
    { label: "Child Seat", value: "Child Seat" },
    { label: "Unlimited KM", value: "Unlimited KM" },
    { label: "Additional Driver", value: "Additional Driver" },
  ];

  useEffect(() => {
    CarModelService.getCarModels();

    return () => {
      dispatch(supplierActions.clear());
    };
  }, [dispatch]);

  return <SelectRedux {...props} options={extraName} />;
}
