import { useEffect } from "react";
import CompanyService from "services/company.service";
import { companyActions } from "redux/slices/company";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function SelectCompany(props: any) {
	// const { id } = useParams();
	const dispatch = useAppDispatch();
	const companyOptions = useAppSelector(
		(state) => state.company.companyOptions
	);

	useEffect(() => {
		
		CompanyService.getCompanies({});

		return () => {
			dispatch(companyActions.clear());
		};
	}, [dispatch]);

	return (
		<SelectRedux
			{...props}
			options={companyOptions}
		// disabled={id ? true : false}
		/>
	);
}
