import { config } from "config";
import { SetBranchesPayload, BranchState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";
import { SetPagePayload, SetTotalCountPayload } from "../user";

const default_page_size = config.PAGE_SIZE;
const initialState: BranchState = {
  count: 0,
  branches: [],
  branch: null,
  loading: true,
  branchOptions: [],
  current_filters: {},
  supplierBranches: [],
  filters: { page: 1, pageSize: default_page_size },
  refresh: 0,
  refreshLoader: false,
  totalCount: 0,
};

export const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    clear: (state) => {
      state.branchOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addBranch: (state, action) => {
      state.branches.unshift(action.payload);
    },
    updateBranch: (state, action) => {
      const { id, branch } = action.payload;
      state.branches.every(({ _id }, i) => {
        if (id === _id) {
          state.branches[i] = branch;
          return false;
        }
        return true;
      });
    },
    deleteBranchById: (state, action) => {
      const id = action.payload;
      state.branches.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.branches.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    setBranches: (state, action: PayloadAction<SetBranchesPayload>) => {
      const {branches } = action.payload;
      let options: SelectOption[] = [];
      branches.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });
      state.branches = branches;
      state.branchOptions = options;
    },
    setTotalCount: (state, action: PayloadAction<SetTotalCountPayload>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
      // state.refresh += 1;
      // state.refreshLoader = true;
    },

    setSupplierBranches: (state, action: PayloadAction<SetBranchesPayload>) => {
      const { branches } = action.payload;
      let options: SelectOption[] = [];
      branches.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });
      state.supplierBranches = options;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const branchReducer = branchSlice.reducer;

export const branchActions = branchSlice.actions;
export default branchReducer;
