import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { carExtraActions } from "redux/slices/carExtra";

const url = "/api/non-api/car-extra";

const CarExtraService = {
  getCarExtra: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const carExtra = success.data;
      dispatch?.(carExtraActions.setCarExtra(carExtra));
    } else dispatch?.(carExtraActions.setCarExtra({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCarExtras: async (data: any = {}) => {
    const dispatch = getAppDispatch();

    dispatch?.(carExtraActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(
        carExtraActions.setCarExtras({ carExtras: documents, totalCount })
      );
    }

    dispatch?.(carExtraActions.setLoading(false));
    return [success, error];
  },

  addCarExtra: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const carExtra = success.data;
      dispatch?.(carExtraActions.addCarExtra(carExtra));
      navigate?.("/car-extras");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCarExtra: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const carExtra = success.data;
      dispatch?.(carExtraActions.setCarExtra(carExtra));
      navigate?.(`/car-extra-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteCarExtra: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(carExtraActions.deleteCarExtraById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CarExtraService;
