import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { carExtraActions } from "redux/slices/carExtra";

export default function CarExtraFilters() {
  const form = "CarExtraFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.modelId) data.modelId = values.modelId;
    if (values.supplierId) data.supplierId = values.supplierId;
    if (values.pageSize) data.pageSize = values.pageSize;

    dispatch(carExtraActions.setFilters(data));
  };
  const formFields = ["docNumber", "modelId", "supplierId", "pageSize"];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(carExtraActions.resetFilters())}
        totalCountSelector={(state: any) => state.carExtra.totalCount}
      />
    </div>
  );
}
