import FormGroup from "@mui/material/FormGroup";
import { Android12Switch, SwitchProps } from ".";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function SwitchCase({
	label = "",
	...rest
}: SwitchProps) {
	return (
		<FormGroup>
			<FormControlLabel
				label={label}
				color="success"
				control={<Android12Switch color="success" {...rest} />}
			/>
		</FormGroup>
	);
}