import { useEffect } from "react";
import { change } from "redux-form";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarService from "services/car.service";
import { carActions } from "redux/slices/car";

export default function UpdateCarForm({ id }: any) {
  const form = "AddCarForm";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const car = useAppSelector((state) => state.car.car);

  useEffect(() => {
    CarService.getCar(id || "");

    return () => {
      dispatch(carActions.setCar(null));
    };
  }, [id, dispatch]);

  useEffect(() => {
    if (!car) return;

    const {
      modelId,
      carPricingId,
      supplierId,
      branchId,
      manufacturingYear,
      numberOfCars,
      fuelType,
      numberOfSeats,
      numberOfBags,
      numberOfDoors,
      transmission,
      isAirConditioned,
      isUnlimitedKM,
      dailyRate,
      weeklyRate,
      monthlyRate,
      extraKMCost,
      freeKMPerDay,
    } = car;

    dispatch(change(form, "modelId", modelId?._id));
    dispatch(change(form, "carPricingId", carPricingId?._id));
    dispatch(change(form, "supplierId", supplierId?._id));
    dispatch(change(form, "branchId", branchId?._id));
    dispatch(change(form, "manufacturingYear", manufacturingYear));
    dispatch(change(form, "numberOfCars", numberOfCars));
    dispatch(change(form, "numberOfSeats", numberOfSeats));
    dispatch(change(form, "numberOfBags", numberOfBags));
    dispatch(change(form, "numberOfDoors", numberOfDoors));
    dispatch(change(form, "transmission", transmission));
    dispatch(change(form, "isAirConditioned", isAirConditioned));

    dispatch(change(form, "isUnlimitedKM", isUnlimitedKM));
    dispatch(change(form, "dailyRate", dailyRate));
    dispatch(change(form, "weeklyRate", weeklyRate));
    dispatch(change(form, "monthlyRate", monthlyRate));
    dispatch(change(form, "extraKMCost", extraKMCost));
    dispatch(change(form, "freeKMPerDay", freeKMPerDay));
    dispatch(change(form, "fuelType", fuelType));
  }, [car, navigate, dispatch]);

  return null;
}
