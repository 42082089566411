import InputRedux from "components/molecules/InputRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import {
  email,
  number,
  phone,
  required,
  requiredAutoComplete,
  requiredPhone,
} from "utils/validate.util";
import SelectCompany from "components/organisms/SelectCompany";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import DatePickerRedux from "components/molecules/DatePickerRedux";

export { default } from "./AddFranchiseForm";

export const fields: ReduxFormField[] = [
  {
    name: "name",
    label: "Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "franchiseCode",
    label: "Franchise Code",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "companyId",
    label: "Company",
    validate: [required],
    component: SelectCompany,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "address",
    label: "Address",
    validate: [required, requiredAutoComplete],
    component: AutoCompleteRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "phone",
    label: "Phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "Email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "mainContactPerson",
    label: "Main Contact Person",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "numberOfEmployees",
    label: "Number Of Employees",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "dateOfEstablishment",
    label: "Date of Establishment",
    validate: [required],
    component: DatePickerRedux,
    cellProps: { md: 4, lg: 4 },
  },
  {
    name: "description",
    label: "Description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12, lg: 12 },
    InputProps: { multiline: true, rows: 5 },
  },
];
