import { AddBookingState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: AddBookingState = {
  loading: false,
  fleetLoading: false,
  searchedModels: [],
  filteredModels: [],
  filters: null,
  selectedFilters: null,
  selectedModel: null,
  extras: null,
  searchedModelPayload: null,
};

export const addBookingSlice = createSlice({
  name: "addBooking",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setFleetLoader: (state, action: PayloadAction<boolean>) => {
      state.fleetLoading = action.payload;
    },
    setSearchedModelPayload: (state, action) => {
      state.searchedModelPayload = action.payload;
    },
    setSearchedModels: (state, action) => {
      state.searchedModels = action.payload;
    },
    setFilteredModels: (state, action) => {
      state.filteredModels = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setSelectedModel: (state, action) => {
      state.selectedModel = action.payload;
    },
    setExtras: (state, action) => {
      state.extras = action.payload;
    },
  },
});

const addBookingReducer = addBookingSlice.reducer;

export const addBookingActions = addBookingSlice.actions;
export default addBookingReducer;
