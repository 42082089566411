import { lazy } from "react";
import { MODAL } from "redux/slices/modal";

const HandleStatus = lazy(() => import("./HandleStatus"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const MarketTransferPopup = lazy(() => import("./MarketTransferPopup"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));
const TicketAddComment = lazy(() => import("./TicketAddComment"));
const REFUND_MODAL = lazy(() => import("./RefundModal"));

export { default } from "./AppModal";

export type ModalMapper = {
  [key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
  HANDLE_STATUS: <HandleStatus />,
  CHANGE_PASSWORD: <ChangePassword />,
  MARKET_TRANSFER: <MarketTransferPopup />,
  CONFIRMATION_FORM: <ConfirmationForm />,
  TICKET_ADD_COMMENT: <TicketAddComment />,
  REFUND_MODAL: <REFUND_MODAL />,
};
