import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { franchiseActions } from "redux/slices/franchise";

export default function FranchisesFilters() {
  const form = "FranchisesFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.franchiseCode) data.franchiseCode = values.franchiseCode;
    if (values.name) data.name = values.name;
    if (values.email) data.email = values.email;
    if (values.phoneNumber) data.phoneNumber = values.phoneNumber.value;
    if (values.companyId) data.companyId = values.companyId;
    if (values.mainContactPerson)
      data.mainContactPerson = values.mainContactPerson;

    if (values.dateOfEstablishment?.date) {
      const date = new Date(values.dateOfEstablishment.date);
      if (!isNaN(date.getTime())) {
        data.dateOfEstablishment = date.toISOString().split("T")[0];
      }
    }
    dispatch(franchiseActions.setFilters(data));
  };
  const formFields = [
    "docNumber",
    "franchiseCode",
    "name",
    "email",
    "phoneNumber",
    "mainContactPerson",
    "dateOfEstablishment",
    "pageSize",
  ];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(franchiseActions.resetFilters())}
        totalCountSelector={(state: any) => state.franchise.totalCount}
      />
    </div>
  );
}
