import { fields } from "./CommonFilterForm";
import CommonFilterForm from "./CommonFilterForm/CommonFilterForm";

export default function Filters({
  filterKeys,
  handleSubmit,
  formName,
  resetFiltersAction,
  totalCountSelector,
}: any) {
  return (
    <div className="filters">
      <CommonFilterForm
        onSubmit={handleSubmit}
        fields={fields(filterKeys) as any}
        form={formName}
        resetFiltersAction={resetFiltersAction}
        totalCountSelector={totalCountSelector}
      />
    </div>
  );
}
