import { useEffect } from "react";
import FranchiseService from "services/franchise.service";
import { franchiseActions } from "redux/slices/franchise";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";

export default function SelectFranchise(props: any) {
	// const { id } = useParams();
	const dispatch = useAppDispatch();
	const franchiseOptions = useAppSelector(
		(state) => state.franchise.franchiseOptions
	);
	const loading = useAppSelector((state) => state.franchise.loading);

	const companyId = useAppSelector(
		(state) => state.form?.["AddEmployeeForm"]?.values?.companyId?._id
	);

	const employeesCompanyId = useAppSelector(
		(state) => state.form?.["EmployeesFiltersForm"]?.values?.companyId?._id
	);

	useEffect(() => {
		if (companyId || employeesCompanyId) {
			FranchiseService.getCompanyFranchise(companyId || employeesCompanyId)
		}
		return () => {
			dispatch(franchiseActions.clear());
		};
	}, [dispatch, companyId, employeesCompanyId]);

	return (
		<SelectRedux
			{...props}
			options={franchiseOptions}
			disabled={loading && (!companyId || !employeesCompanyId)}
		/>
	);
}