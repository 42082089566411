import http from "./http.service";
import { getAppDispatch } from "utils/dispatch.util";
import { addBookingActions } from "redux/slices/addBooking";
import Promisable from "./promisable.service";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import {
  createCarCategoryFilters,
  createFuelTypeFilters,
  createManufacturersFilters,
  createManufacturingYearFilters,
  createOtherFilters,
  createSupplierFilters,
  createTransmissionFilters,
} from "utils/filters.utils";
import LocalStorage from "utils/localstorage.util";
import { bookingActions } from "redux/slices/booking";

const url = "/gateway";

const AddBookingService = {
  searchModels: async (payload: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(addBookingActions.setFleetLoader(true));
    http.setJWT();
    let sse: EventSource | null = null;
    let streamedData: any[] = [];
    const queryParams = new URLSearchParams(payload).toString();
    sse = new EventSource(
      `${process.env.REACT_APP_API_URL}/gateway/cars/available/admin?${queryParams}`
    );

    sse.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);
        if (parsedData) {
          dispatch?.(
            addBookingActions.setSearchedModels([
              ...streamedData,
              ...parsedData,
            ])
          );
          streamedData = [...streamedData, ...parsedData];
        }
        console.log("Data received:", parsedData);
      } catch (err) {
        console.error("Error parsing data:", err);
      }
    };

    sse.onerror = (err) => {
      dispatch?.(addBookingActions.setFleetLoader(false));
      console.error("EventSource error:", err);
      sse?.close(); // Close the SSE connection
      sse = null;
    };
    sse.addEventListener("end", () => {
      console.log("Data stream finished.", streamedData);
      dispatch?.(addBookingActions.setFleetLoader(false));
      dispatch?.(addBookingActions.setSearchedModels(streamedData));
      dispatch?.(addBookingActions.setFilteredModels(streamedData));

      let manufacturers = createManufacturersFilters(streamedData);
      let carCategories = createCarCategoryFilters(streamedData);
      let suppliers = createSupplierFilters(streamedData);
      let manufacturingYears = createManufacturingYearFilters(streamedData);
      let fuelTypes = createFuelTypeFilters(streamedData);
      let transmissions = createTransmissionFilters(streamedData);
      let others = createOtherFilters(streamedData);
      dispatch?.(
        addBookingActions.setFilters({
          manufacturers,
          carCategories,
          suppliers,
          manufacturingYears,
          fuelTypes,
          transmissions,
          others,
        })
      );
      LocalStorage.setItem("models", streamedData);
      LocalStorage.setItem("filters", {
        manufacturers,
        carCategories,
        suppliers,
        manufacturingYears,
        fuelTypes,
        transmissions,
        others,
      });

      sse?.close(); // Close the SSE connection
      sse = null;
    });
  },

  getExtras: async (payload: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(addBookingActions.setLoader(true));

    http.setJWT();

    const queryParams = new URLSearchParams(payload).toString();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/extras/available?${queryParams}`)
    );

    if (success) {
      const data = success.data;
      dispatch?.(addBookingActions.setExtras(data));
    }

    dispatch?.(addBookingActions.setLoader(false));
    return [success, error];
  },
  addBooking: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`/api/bookings`, data)
    );

    if (success) {
      // const booking = success.data;
      // dispatch?.(bookingActions.addBooking(booking));
      localStorage.removeItem("models");
      localStorage.removeItem("filters");
      localStorage.removeItem("selectedModel");
      localStorage.removeItem("selectedFilters");
      localStorage.removeItem("adminTotalPrice");
      localStorage.removeItem("searchedModelPayload");
      navigate?.("/bookings");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
  updateBooking: async (id: string, data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`/api/bookings/${id}`, data)
    );

    if (success) {
      const booking = success.data;
      dispatch?.(bookingActions.setBooking(booking));
      navigate?.(`/booking-details/${booking?._id}`);
      localStorage.removeItem("totalPrice");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },
};

export default AddBookingService;
