import "./Chip.css";
import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles((theme: any) => ({
  primary: {
    color: theme.palette.primary.main,
    background: `rgba(96, 17, 135, 0.08)`,
  },
  success: {
    color: theme.palette.success.main,
    background: `${theme.palette.success.light}`,
  },
  info: {
    color: theme.palette.info.main,
    background: `${theme.palette.info.light}`,
  },
  warning: {
    color: theme.palette.warning.main,
    background: `${theme.palette.warning.light}`,
  },
  error: {
    color: theme.palette.error.main,
    background: `${theme.palette.error.light}`,
  },
}));

export default function Chip({ status, ...rest }: any) {
  const classes = useStyles();
  return (
    <span
      className={`class-badge ${
        status === "confirm" || status === "finish" || status === "active"
          ? classes.success
          : status === "confirm"
          ? classes.info
          : status === "un_confirmed" || status === "cancel"
          ? classes.error
          : classes.warning
      }`}
      {...rest}
    >
      {status === "paused" ? "on wait" : status}
    </span>
  );
}
