import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { length4, number, required } from "utils/validate.util";
import SelectRedux from "components/molecules/SelectRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import SelectCarCategory from "components/organisms/SelectCarCategory";
import FileUploadRedux from "components/molecules/FileUploadRedux";

export { default } from "./AddCarModelForm";

export const fields: ReduxFormField[] = [
  {
    name: "name",
    label: "Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "manufacturer",
    label: "Manufacturer",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "carImage",
    label: "Car Image",
    validate: [required],
    component: FileUploadRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "carCategoryId",
    label: "Car Category",
    validate: [required],
    component: SelectCarCategory,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "isAirConditioned",
    label: "Air Conditioned",
    component: CheckboxRedux,
    cellProps: { md: 2 },
  },
  {
    name: "transmission",
    label: "Transmission",
    validate: [required],
    component: SelectRedux,
    cellProps: { md: 2 },
    SelectProps: {
      options: [
        { value: "auto", label: "Auto" },
        { value: "manual", label: "Manual" },
      ],
    },
  },
  {
    name: "acrissCode",
    label: "ACRISS Code",
    validate: [required, length4],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfSeats",
    label: "Number Of Seats",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfBags",
    label: "Number Of Bags",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
  {
    name: "numberOfDoors",
    label: "Number Of Doors",
    validate: [required, number],
    component: InputRedux,
    cellProps: { md: 2 },
  },
];
