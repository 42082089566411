import Input from "../Input";
import { useEffect, useRef, useState } from "react";
import ToasterService from "utils/toaster.util";
import { AutoCompleteOwnProps, fields } from ".";
export type AutoCompletesProps = AutoCompleteOwnProps &
  React.ComponentProps<typeof Input>;
export default function AutoComplete({
  val,
  value,
  handleChange,
  ...rest
}: AutoCompletesProps) {
  const ref = useRef<any>(null);
  const isInit = useRef(false);
  const [isApiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    function checkGoogleMapsApi() {
      if (window.google && window.google.maps && window.google.maps.places) {
        if (!isApiLoaded) {
          setApiLoaded(true);
        }
      }
    }

    const intervalId = setInterval(checkGoogleMapsApi, 100); // Check every 100 milliseconds
    return () => {
      clearInterval(intervalId);
    };
  }, [isApiLoaded]);

  useEffect(() => {
    function initAutocomplete() {
      if (isApiLoaded && ref.current) {
        const autocomplete = new window.google.maps.places.Autocomplete(
          ref.current,
          {
            fields,
          }
        );
        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          if (!place?.geometry?.location)
            return ToasterService.showError(
              `No details available for input: '${place.name}'`
            );
          let city: any;
          const location = place.geometry.location;
          const geometry = {
            location: { lat: location.lat(), lng: location.lng() },
          };
          place?.address_components?.every(({ types, long_name }: any) => {
            if (
              types.includes("locality") ||
              types.includes("administrative_area_level_1")
            )
              city = long_name;
            if (city === "İstanbul") city = "istanbul";
            // if (city) return false;
            return true;
          });

          let country: any;
          place?.address_components?.every(({ types, long_name }: any) => {
            if (types.includes("country")) country = long_name;
            return true;
          });
          handleChange({
            value: place.formatted_address,
            address: {
              lat: geometry.location.lat,
              lng: geometry.location.lng,
              formattedAddress: place.formatted_address,
              city,
              country,
            },
          });
        });
        isInit.current = true;
      }
    }

    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error("Google Maps JavaScript API not loaded");
    } else {
      initAutocomplete();
    }
  }, [handleChange, isApiLoaded]);

  return (
    <Input
      inputRef={ref}
      placeholder={`Enter ${rest.label} Location`}
      {...rest}
      value={val?.value || ""}
      label={val?.details?.name || rest.label}
      onChange={(e) => handleChange({ value: e.target.value })}
      onKeyDown={(e) => {
        rest.onKeyDown?.(e);
        if (e.key === "Enter") e.preventDefault();
      }}
    />
  );
}
