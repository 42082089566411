import http from "./http.service";
import Promisable from "./promisable.service";
import { carCategoryActions } from "redux/slices/carCategory";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";

const url = "/api/non-api/car-category";

const CarCategoryService = {
  getCarCategory: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const carCategory = success.data;
      dispatch?.(carCategoryActions.setCarCategory(carCategory));
    } else dispatch?.(carCategoryActions.setCarCategory({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCarCategories: async (data: any = {}) => {
    const dispatch = getAppDispatch();

    dispatch?.(carCategoryActions.setLoading(true));

    http.setJWT();

    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(
        carCategoryActions.setCarCategories({
          carCategories: documents,
          totalCount,
        })
      );
    }

    dispatch?.(carCategoryActions.setLoading(false));
    return [success, error];
  },

  addCarCategory: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const carCategory = success.data;
      dispatch?.(carCategoryActions.addCarCategory(carCategory));
      navigate?.("/car-categories");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCarCategory: async (
    id: string,
    data: any,
    navigate: NavigateFunction
  ) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const carCategory = success.data;
      dispatch?.(carCategoryActions.setCarCategory(carCategory));
      navigate?.(`/car-categories`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteCarCategory: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(carCategoryActions.deleteCarCategoryById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CarCategoryService;
