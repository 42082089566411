export const config = {
	NODE_ENV: process.env.NODE_ENV,
	APP_ENV: process.env.REACT_APP_ENV,

	APP_URL: `${process.env.REACT_APP_URL}`,
	API_URL: process.env.REACT_APP_API_URL,
	LOCAL_API_URL: process.env.REACT_APP_LOCAL_API_URL,

	ONE_SIGNAL_APP_ID: `${process.env.REACT_APP_ONE_SIGNAL_APP_ID}`,
	GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	PAGE_SIZE: Number(process.env.REACT_APP_PAGE_SIZE) || 30,
};
