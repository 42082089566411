import { Stack } from '@mui/material';
import { UserMenu } from '../user-menu/UserMenu';

export const ToolbarElements = () => {

  return (
    <Stack direction={'row'} spacing={2}>
      <UserMenu />
    </Stack>
  );
};
