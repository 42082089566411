import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      success: object;
      warning: object;
    };
  }
  interface ThemeOptions {
    status?: {
      success?: object;
      warning?: object;
    };
  }
}

const theme = createTheme({
  status: {
    success: {
      color: "rgba(84, 214, 44, 0.16)",
      textContrast: "rgb(34, 154, 22)",
    },
    warning: {
      color: "rgba(255, 193, 7, 0.16)",
      textContrast: "rgb(183, 129, 3)",
    },
  },
  palette: {
    primary: {
      light: "#ed1c24",
      main: "#ed1c24",
      dark: "#000000",
      // contrastText: "rgb(2, 136, 209)",
    },
    secondary: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
      // contrastText: "rgb(2, 136, 209)",
    },
    success: {
      main: "rgb(34, 154, 22)",
      light: "rgba(84, 214, 44, 0.16)",
      dark: "#1b5e20",
      // contrastText: "rgb(34, 154, 22)",
    },
    warning: {
      main: "rgb(183, 129, 3)",
      light: "rgba(255, 193, 7, 0.16)",
      dark: "#e65100",
      // contrastText: "rgb(183, 129, 3)"
    },
    error: {
      main: "rgb(183, 33, 54)",
      light: "rgba(255, 72, 66, 0.16)",
      dark: "#c62828",
      // contrastText: "rgb(183, 33, 54)",
    },
    info: {
      main: "rgb(2, 136, 209)",
      light: "rgb(2, 136, 209, 0.16)",
      dark: "#01579b",
      // contrastText: "rgb(2, 136, 209)",
    },
  },
  typography: {
    fontFamily: '"Noto Sans", sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
  },
});

export default theme;
