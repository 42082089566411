import React from 'react';
import { PageHeaderTitle } from './styled';
import { Breadcrumbs, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface PageHeaderProps {
  title: string;
  breadcrumbs?: any[];
  renderRight?: React.ReactNode;
}

export const PageHeader = ({ title, breadcrumbs, renderRight }: PageHeaderProps) => {
  const breadcrumbsList = breadcrumbs?.map((breadcrumb, index) => (
    breadcrumb.link ? <Link key={index} to={breadcrumb.link} style={{ fontSize: "14px", textDecoration: "none" }}>
      {breadcrumb.title}
    </Link> : <Typography key={index} fontSize={14}>
      {breadcrumb.title}
    </Typography>
  ));

  return (
    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} marginBottom={2}>
      <Stack spacing={2} marginBottom={2}>
        <PageHeaderTitle variant={'h1'}>{title}</PageHeaderTitle>
        {breadcrumbs ? (
          <Breadcrumbs separator='•' aria-label='breadcrumb'>
            {breadcrumbsList}
          </Breadcrumbs>
        ) : null}
      </Stack>
      {renderRight ? <div>{renderRight}</div> : null}
    </Stack>
  );
};
