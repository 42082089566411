import InputRedux from "components/molecules/InputRedux";
import PhoneInputRedux from "components/molecules/PhoneInputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { email, phone, required, requiredAutoComplete, requiredPhone } from "utils/validate.util";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import SelectSupplier from "components/organisms/SelectSupplier";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import TimePickerRedux from "components/organisms/TimePickerRedux";

export { default } from "./AddBranchForm";

export const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const fields: ReduxFormField[] = [
  {
    name: "name",
    label: "Name",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "branchCode",
    label: "Branch Code",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "supplierId",
    label: "Supplier",
    validate: [required],
    component: SelectSupplier,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "address",
    label: "Address",
    validate: [required, requiredAutoComplete],
    component: AutoCompleteRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "phone",
    label: "Phone",
    validate: [requiredPhone, phone],
    component: PhoneInputRedux,
    cellProps: { md: 6, lg: 6 },
  },
  {
    name: "email",
    label: "Email",
    validate: [required, email],
    component: InputRedux,
    cellProps: { md: 6, lg: 6 },
    InputProps: { type: "email" },
  },
  {
    name: "description",
    label: "Description",
    validate: [required],
    component: InputRedux,
    cellProps: { md: 12, lg: 12 },
    InputProps: { multiline: true, rows: 5 },
  },
];

export const dayFields: ReduxFormField[] = [
  {
    name: "monday",
    label: "Monday",
    validate: [required],
    component: CheckboxRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "is24Hours",
    label: "24 Hours",
    validate: [required],
    component: CheckboxRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "holiday",
    label: "Holiday",
    validate: [required],
    component: CheckboxRedux,
    cellProps: { md: 2, lg: 2 },
  },
  {
    name: "startTime",
    label: "Start Time",
    validate: [required],
    component: TimePickerRedux,
    cellProps: { md: 3, lg: 3 },
  },
  {
    name: "endTime",
    label: "End Time",
    validate: [required],
    component: TimePickerRedux,
    cellProps: { md: 3, lg: 3 },
  },
];
