import { config } from "config";
import { SetCarExtrasPayload, CarExtraState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";
import { SetPagePayload } from "../user";

const default_page_size = config.PAGE_SIZE;
const initialState: CarExtraState = {
  totalCount: 0,
  carExtras: [],
  carExtra: null,
  loading: true,
  refresh: 0,
  refreshLoader: false,
  current_filters: {},
  carExtraOptions: [],
  filters: { page: 1, pageSize: default_page_size },
};

export const carExtraSlice = createSlice({
  name: "carExtra",
  initialState,
  reducers: {
    clear: (state) => {
      state.carExtraOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addCarExtra: (state, action) => {
      state.carExtras.unshift(action.payload);
    },
    updateCarExtra: (state, action) => {
      const { id, carExtra } = action.payload;
      state.carExtras.every(({ _id }, i) => {
        if (id === _id) {
          state.carExtras[i] = carExtra;
          return false;
        }
        return true;
      });
    },
    deleteCarExtraById: (state, action) => {
      const id = action.payload;
      state.carExtras.every(({ _id }, i) => {
        if (id === _id) {
          state.totalCount -= 1;
          state.carExtras.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setCarExtra: (state, action) => {
      state.carExtra = action.payload;
    },
    setCarExtras: (state, action: PayloadAction<SetCarExtrasPayload>) => {
      const { totalCount, carExtras } = action.payload;
      let options: SelectOption[] = [];

      carExtras.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });

      state.totalCount = totalCount;
      state.carExtras = carExtras;
      state.carExtraOptions = options;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const carExtraReducer = carExtraSlice.reducer;

export const carExtraActions = carExtraSlice.actions;
export default carExtraReducer;
