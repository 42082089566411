import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { insuranceActions } from "redux/slices/insurance";
import { InsServiceOpts } from "pages/AddInsurance/AddInsuranceForm";

const url = "/api/non-api/insurance";

const InsuranceService = {
  getInsurance: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const insurance = success.data;
      dispatch?.(insuranceActions.setInsurance(insurance));
    } else dispatch?.(insuranceActions.setInsurance({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getInsurances: async (data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(insuranceActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(insuranceActions.setInsurances({ documents }));
      dispatch?.(insuranceActions.setTotalCount({ totalCount }));
    }

    dispatch?.(insuranceActions.setLoading(false));
    return [success, error];
  },

  addInsurance: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    const allValues = InsServiceOpts;
    const excludesSet = new Set(data.excludes?.map((item: { value: string }) => item.value) || []);
    data.excludes = Array.from(excludesSet);

    if (excludesSet.size === allValues.length) {
      data.includes = [];
    } else if (data.excludes.length === 0) {
      data.includes = allValues.map(option => option.value);
    } else {
      data.includes = allValues.filter(option => !excludesSet.has(option.value)).map(option => option.value);
    }

    http.setJWT();
    http.setMultiPart();

    const [success, error]: any = await Promisable.asPromise(http.post(`${url}`, data));

    if (success) {
      const insurance = success.data;
      dispatch?.(insuranceActions.addInsurance(insurance));
      navigate?.("/insurance");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateInsurance: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    
    const allValues = InsServiceOpts;
    const excludesSet = new Set(data.excludes?.map((item: { value: string }) => item.value) || []);
    data.excludes = Array.from(excludesSet);

    if (excludesSet.size === allValues.length) {
      data.includes = [];
    } else if (data.excludes.length === 0) {
      data.includes = allValues.map(option => option.value);
    } else {
      data.includes = allValues.filter(option => !excludesSet.has(option.value)).map(option => option.value);
    }

    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const insurance = success.data;
      dispatch?.(insuranceActions.setInsurance(insurance));
      navigate?.(`/insurance-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteInsurance: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(insuranceActions.deleteInsuranceById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default InsuranceService;
