import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddCarExtraForm from "./AddCarExtraForm";
import UpdateCarExtraForm from "./UpdateCarExtraForm";
import CarExtraService from "services/carExtra.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";

export default function AddCarExtra() {
  const { id } = useParams();
  const navigate = useNavigate();
  const carExtra = useAppSelector((state) => state.carExtra.carExtra);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    const data = {
      ...values,
      extras: values.extras.map(
        (extra: { dailyRate: string; maxBuyingCapacity: string }) => ({
          ...extra,
          dailyRate: parseFloat(extra.dailyRate),
          maxBuyingCapacity: parseInt(extra.maxBuyingCapacity, 10),
        })
      ),
    };

    if (id) {
      CarExtraService.updateCarExtra(`${id}`, data, navigate);
    } else CarExtraService.addCarExtra(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${
            id && carExtra ? `/car-extra-details/${id}` : "/car-extras"
          }`}
          title={`Back to ${
            id && carExtra ? "Car Extra Details" : "Car Extras"
          } `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Car Extra`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && carExtra === "Not found" ? (
            <p>CarExtra Not Found</p>
          ) : (
            <AddCarExtraForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateCarExtraForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
