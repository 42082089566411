import CarModelsList from "./CarModelsList";
import { Container } from "@mui/material";
import Button from "components/atoms/Button";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { useNavigate } from "react-router-dom";

export default function CarModels() {
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="xl">
        <PageHeader
          title="Car Models"
          renderRight={
            <Button
              variant="contained"
              onClick={() => navigate("/add-car-model")}
            >
              Add Model
            </Button>
          }
        />
        <CarModelsList showFilters />
      </Container>
    </div>
  );
}
