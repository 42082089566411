import { useEffect } from "react";
import SupplierService from "services/supplier.service";
import { supplierActions } from "redux/slices/supplier";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useLocation } from "react-router-dom";

export default function SelectSupplier(props: any) {
  // const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const supplierNames = useAppSelector(
    (state) => state.supplier.supplierOptions
  );

  useEffect(() => {
    SupplierService.getSuppliers({});

    return () => {
      dispatch(supplierActions.clear());
    };
  }, [dispatch]);

  return (
    <SelectRedux
      {...props}
      options={
        pathname === "/search-fleet"
          ? [{ value: "all", label: "All" }, ...supplierNames]
          : supplierNames
      }
    />
  );
}
