import React from "react";
import { Grid, Stack, Typography } from "@mui/material";
import Button from "components/atoms/Button";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { reset, change } from "redux-form";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import { FiltersFormProps } from ".";

const FiltersForm: React.FC<FiltersFormProps> = ({
  form,
  handleSubmit,
  fields,
  resetFiltersAction,
  totalCountSelector,
  extraButtonProps,
}) => {
  const dispatch = useAppDispatch();
  const totalCount = useAppSelector(totalCountSelector);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <ReduxFormFields fields={fields} rowProps={{ spacing: 0.5 }} />
        </Grid>
        <Grid item xs={4} sm={2} md={1}>
          <Stack
            direction={"row"}
            gap={0.5}
            justifyContent={"space-between"}
            display={"flex"}
          >
            <Button
              color="error"
              variant="outlined"
              type="reset"
              size="large"
              onClick={() => {
                resetFiltersAction();
                dispatch(reset(form));
                if (extraButtonProps?.resetAction) {
                  dispatch(change(form, "dateOfBirth", null));
                }
              }}
              sx={{ height: "45px" }}
            >
              Reset
            </Button>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              fullWidth
            >
              Filter
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={8} sm={10} md={11}>
          <Stack textAlign="right">
            <Typography>
              {totalCount} {totalCount > 1 ? "Results" : "Result"}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default FiltersForm;
