import CarSpecsList from "./CarSpecsList";
import { Container } from "@mui/material";
import Button from "components/atoms/Button";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { useNavigate } from "react-router-dom";

export default function CarSpecs() {
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="xl">
        <PageHeader
          title="Yelo Car Specs"
          renderRight={
            <Button
              variant="contained"
              onClick={() => navigate("/add-car-specs")}
            >
              Add Specs
            </Button>
          }
        />
        <CarSpecsList showFilters />
      </Container>
    </div>
  );
}
