import http from "./http.service";
import Promisable from "./promisable.service";
import { supplierActions } from "redux/slices/supplier";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import ImageService from "./image.service";

const url = "/api/non-api/supplier";

const SupplierService = {
  getSupplier: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const supplier = success.data;
      dispatch?.(supplierActions.setSupplier(supplier));
    } else dispatch?.(supplierActions.setSupplier({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getSuppliers: async (data: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(supplierActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(supplierActions.setSuppliers({ suppliers: documents }));
      dispatch?.(supplierActions.setTotalCount({ totalCount }))
    }

    dispatch?.(supplierActions.setLoading(false));
    return [success, error];
  },

  addSupplier: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();

    const formData = new FormData();

    let img = await ImageService.getImageFileFromBlob(data.businessLogo);

    formData.append("photo", img);
    formData.append("name", data.name);
    formData.append("legalName", data.legalName);
    formData.append("mainContactPerson", data.mainContactPerson);
    formData.append("numberOfVehicles", data.numberOfVehicles);
    formData.append("taxId", data.taxId);
    formData.append("registrationNumber", data.registrationNumber);
    formData.append("email", data.email);
    formData.append("description", data.description);
    formData.append("phoneNumber", JSON.stringify(data.phoneNumber));
    formData.append("address", JSON.stringify(data.address));

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, formData)
    );

    if (success) {
      const supplier = success.data;
      dispatch?.(supplierActions.addSupplier(supplier));
      navigate?.("/suppliers");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateSupplier: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    http.setMultiPart();
    dispatch?.(formLoaderActions.setLoading(true));

    const formData = new FormData();

    if (typeof data?.businessLogo !== "string") {
      let img = await ImageService.getImageFileFromBlob(data.businessLogo);
      formData.append("photo", img);
    }

    formData.append("name", data.name);
    formData.append("legalName", data.legalName);
    formData.append("mainContactPerson", data.mainContactPerson);
    formData.append("numberOfVehicles", data.numberOfVehicles);
    formData.append("taxId", data.taxId);
    formData.append("registrationNumber", data.registrationNumber);
    formData.append("email", data.email);
    formData.append("description", data.description);
    formData.append("phoneNumber", JSON.stringify(data.phoneNumber));
    formData.append("address", JSON.stringify(data.address));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, formData)
    );

    if (success) {
      const supplier = success.data;
      dispatch?.(supplierActions.setSupplier(supplier));
      navigate?.(`/supplier-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteSupplier: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(supplierActions.deleteSupplierById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default SupplierService;
