import { Grid, Typography } from "@mui/material";
import AutoCompleteRedux from "components/molecules/AutoCompleteRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";
import ReduxFormFields from "components/molecules/ReduxFormFields";
import React, { useEffect } from "react";
import { change, Field, reduxForm } from "redux-form";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { required, requiredAutoComplete } from "utils/validate.util";
import { fields } from ".";
import Button from "components/atoms/Button";
import SelectBranch from "components/organisms/SelectBranch";

const UpdateBookingDateTimeForm = ({ handleSubmit }: any) => {
  const dispatch = useAppDispatch();
  const booking = useAppSelector((state) => state.booking?.booking);
  const checkDropOff = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values?.checkDropOff
  );

  // useEffect(() => {
  //   dispatch(
  //     change(
  //       "UpdateBookingForm",
  //       "pickup",
  //       booking?.pickupBranchDetail?.id
  //     )
  //   );
  //   dispatch(
  //     change("UpdateBookingForm", "dates", {
  //       error: [null, null],
  //       date: [
  //         booking?.pickupDate.replace("Z", ""),
  //         booking?.dropOffDate.replace("Z", ""),
  //       ],
  //     })
  //   );
  //   dispatch(
  //     change("UpdateBookingForm", "pickupTime", {
  //       error: null,
  //       date: booking?.pickupDate.replace("Z", ""),
  //     })
  //   );
  //   dispatch(
  //     change("UpdateBookingForm", "dropOffTime", {
  //       error: null,
  //       date: booking?.dropOffDate.replace("Z", ""),
  //     })
  //   );
  // }, [booking]);

  return (
    <>
      {/* <form onSubmit={handleSubmit}> */}
      <Typography fontWeight={600} mb={1}>
        Update Branch & Date/Time
      </Typography>

      {/* <Field
        component={CheckboxRedux}
        label="Different DropOff"
        name="checkDropOff"
      /> */}
      <Grid container justifyContent="space-between" columnSpacing={2} mb={2}>
        <Grid item md={checkDropOff ? 6 : 12}>
          <Field
            component={SelectBranch}
            name="pickup"
            label="Pickup"
            validate={[required]}
          />
        </Grid>
        {booking?.pickupBranchId !== booking?.dropOffBranchId && (
          <Grid item md={6}>
            <Field
              component={AutoCompleteRedux}
              name="dropOff"
              label="Drop-off"
              validate={[requiredAutoComplete]}
            />
          </Grid>
        )}
      </Grid>
      <Grid container columnSpacing={2} mb={2}>
        <Grid item xs={12} md={12}>
          <ReduxFormFields fields={fields} />
        </Grid>
      </Grid>
      {/* <Button variant="contained">Continue</Button> */}
      {/* </form> */}
    </>
  );
};

export default // reduxForm({ form: "UpdateBookingForm" })(
UpdateBookingDateTimeForm;
// );
