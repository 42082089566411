import { config } from "config";
import { SetPromotionPayload, PromotionState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";
import { SetTotalCountPayload } from "../booking";
import { SetPagePayload } from "../user";

const default_page_size = config.PAGE_SIZE;
const initialState: PromotionState = {
  count: 0,
  totalCount: 0,
  documents: [],
  promotion: null,
  loading: true,
  promotionOptions: [],
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
  refresh: 0,
  refreshLoader: false,
};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    clear: (state) => {
      state.promotionOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addPromotion: (state, action) => {
      state.documents.unshift(action.payload);
    },
    updatePromotion: (state, action) => {
      const { id, promotion } = action.payload;
      state.documents.every(({ _id }, i) => {
        if (id === _id) {
          state.documents[i] = promotion;
          return false;
        }
        return true;
      });
    },
    deletePromotionById: (state, action) => {
      const id = action.payload;
      state.documents.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.documents.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setPromotion: (state, action) => {
      state.promotion = action.payload;
    },
    setTotalCount: (state, action: PayloadAction<SetTotalCountPayload>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
      // state.refresh += 1;
      // state.refreshLoader = true;
    },
    setPromotions: (state, action: PayloadAction<SetPromotionPayload>) => {
      const { documents } = action.payload;
      let options: SelectOption[] = [];

      documents?.forEach(({ _id, legalName }: any) => {
        const option = { value: _id, label: `${legalName}` };
        options.push(option);
      });

      state.documents = documents;
      state.promotionOptions = options;
    },

    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const promotionReducer = promotionSlice.reducer;

export const promotionActions = promotionSlice.actions;
export default promotionReducer;
