export const createManufacturersFilters = (models: any[]) => {
  const manufacturers = Array.from(
    new Set(models.map((m: any) => m.manufacturer).filter((item) => item))
  );

  manufacturers.unshift("all");
  return manufacturers.map((name, index) => ({
    id: (index + 1).toString(),
    name: name?.toLowerCase(),
    disabled: false,
    slug:
      name.toLowerCase() === "all"
        ? "all"
        : name.toLowerCase(),
  }));
};

export const createCarCategoryFilters = (models: any[]) => {
  const carCategories = Array.from(
    new Set(models.map((m: any) => m.carCategory).filter((item) => item))
  );

  carCategories.unshift("all");

  return carCategories.map((name, index) => ({
    id: (index + 1).toString(),
    name: name?.toLowerCase(),
    disabled: false,
    slug:
      name.toLowerCase() === "all"
        ? "all"
        : name.toLowerCase(),
  }));
};

export const createSupplierFilters = (models: any[]) => {
  const suppliers = Array.from(
    new Set(models.map((m: any) => m.supplierName).filter((item) => item))
  );

  suppliers.unshift("all");
  return suppliers.map((name, index) => ({
    id: (index + 1).toString(),
    name: name?.toLowerCase(),
    disabled: false,
    slug: name.toLowerCase() === "all" ? "all" : name.toLowerCase(),
  }));
};

export const createManufacturingYearFilters = (models: any[]) => {
  const manufacturingYears = Array.from(
    new Set(models.map((m: any) => m.manufacturingYear).filter((item) => item))
  );

  manufacturingYears.unshift("all");
  return manufacturingYears.map((name, index) => ({
    id: (index + 1).toString(),
    name: name?.toString()?.toLowerCase(),
    disabled: false,
    slug:
      name.toString().toLowerCase() === "all"
        ? "all"
        : name.toString().toLowerCase(),
  }));
};

export const createFuelTypeFilters = (models: any[]) => {
  const fuelTypes = Array.from(
    new Set(models.map((m: any) => m.fuelType).filter((item) => item))
  );

  fuelTypes.unshift("all");
  return fuelTypes.map((name, index) => ({
    id: (index + 1).toString(),
    name: name?.toLowerCase(),
    disabled: false,
    slug:
      name.toLowerCase() === "all"
        ? "all"
        : name.toLowerCase(),
  }));
};

export const createTransmissionFilters = (models: any[]) => {
  const transmissions = Array.from(
    new Set(models.map((m: any) => m.transmission).filter((item) => item))
  );

  transmissions.unshift("all");
  return transmissions.map((name, index) => ({
    id: (index + 1).toString(),
    name: name?.toLowerCase(),
    disabled: false,
    slug:
      name.toLowerCase() === "all"
        ? "all"
        : name.toLowerCase(),
  }));
};

export const createOtherFilters = (models: any[]) => {
  const tags = Array.from(
    new Set(models.flatMap((item) => item.tags).filter((item) => item))
  );

  tags.unshift("all");
  return tags.map((name, index) => ({
    id: (index + 1).toString(),
    name: name?.toLowerCase(),
    disabled: false,
    slug:
      name.toLowerCase() === "all"
        ? "all"
        : name.toLowerCase(),
  }));
};

export function filterTheModels(models: any, selectedFilters: any, sort: any) {
  // Apply filters
  const filteredModels = models.filter((car: any) => {
    // Check manufacturer filter - skip if 'all', empty, or undefined
    if (selectedFilters)
      if (
        selectedFilters.manufacturer &&
        !selectedFilters.manufacturer.includes("all") &&
        !selectedFilters.manufacturer.includes(car.manufacturer.toLowerCase())
      ) {
        return false;
      }

    // Check carCategory filter - skip if 'all', empty, or undefined
    if (
      selectedFilters.carCategory &&
      selectedFilters.carCategory.length > 0 &&
      !selectedFilters.carCategory.includes("all") &&
      !selectedFilters.carCategory.includes(car.carCategory?.toLowerCase())
    ) {
      return false;
    }

    // Check supplier filter - skip if 'all', empty, or undefined
    if (
      selectedFilters.supplier &&
      selectedFilters.supplier.length > 0 &&
      !selectedFilters.supplier.includes("all") &&
      !selectedFilters.supplier.includes(car.supplierName.toLowerCase())
    ) {
      console.log(
        "Filter",
        selectedFilters.supplier,
        "Car name",
        car.supplierName
      );

      return false;
    }

    // Check manufacturingYear filter - skip if 'all', empty, or undefined
    if (
      selectedFilters.manufacturingYear &&
      selectedFilters.manufacturingYear.length > 0 &&
      !selectedFilters.manufacturingYear.includes("all") &&
      !selectedFilters.manufacturingYear.includes(
        car.manufacturingYear.toString()
      )
    ) {
      return false;
    }

    // Check fuelType filter - skip if 'all', empty, or undefined
    if (
      selectedFilters.fuelType &&
      selectedFilters.fuelType.length > 0 &&
      !selectedFilters.fuelType.includes("all") &&
      !selectedFilters.fuelType.includes(car.fuelType.toLowerCase())
    ) {
      return false;
    }

    // Check transmission filter - skip if 'all', empty, or undefined
    if (
      selectedFilters.transmission &&
      selectedFilters.transmission.length > 0 &&
      !selectedFilters.transmission.includes("all") &&
      !selectedFilters.transmission.includes(car.transmission.toLowerCase())
    ) {
      return false;
    }

    // Check other attributes filter - skip if 'all', empty, or undefined
    if (
      selectedFilters.other &&
      selectedFilters.other.length > 0 &&
      !selectedFilters.other.includes("all") &&
      !car.tags.some((value: any) =>
        selectedFilters.other.includes(value.toLowerCase())
      )
    ) {
      return false;
    }

    return true;
  });

  // Sorting based on the 'sort' parameter
  switch (sort) {
    case "lowest":
      return filteredModels.sort(
        (a: any, b: any) => a.totalPrice - b.totalPrice
      );

    case "highest":
      return filteredModels.sort(
        (a: any, b: any) => b.totalPrice - a.totalPrice
      );

    case "newest":
      return filteredModels.sort(
        (a: any, b: any) => b.manufacturingYear - a.manufacturingYear
      );

    case "oldest":
      return filteredModels.sort(
        (a: any, b: any) => a.manufacturingYear - b.manufacturingYear
      );

    default:
      return filteredModels.sort(
        (a: any, b: any) => a.totalPrice - b.totalPrice
      );
  }
}
