import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { carSpecActions } from "redux/slices/carSpec";

export default function CarSpecsFilters() {
  const form = "CarSpecsFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.isAirConditioned)
      data.isAirConditioned = values.isAirConditioned;
    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.acrissCode) data.acrissCode = values.acrissCode;
    if (values.numberOfSeats) data.numberOfSeats = values.numberOfSeats;
    if (values.numberOfDoors) data.numberOfDoors = values.numberOfDoors;
    if (values.numberOfBags) data.numberOfBags = values.numberOfBags;
    if (values.pageSize) data.pageSize = values.pageSize;

    dispatch(carSpecActions.setFilters(data));
  };
  const formFields = [
    "isAirConditioned",
    "docNumber",
    "acrissCode",
    "numberOfSeats",
    "numberOfDoors",
    "numberOfBags",
    "pageSize",
  ];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(carSpecActions.resetFilters())}
        totalCountSelector={(state: any) => state.carSpec.totalCount}
      />
    </div>
  );
}
