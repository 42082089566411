import React from "react";
import { InjectedFormProps, reduxForm, reset } from "redux-form";
import FiltersForm from "components/molecules/FiltersForm";

interface CommonFilterFormProps {
  fields: any;
  resetFiltersAction: any;
  totalCountSelector: any;
}
type CombinedProps = InjectedFormProps<{}, CommonFilterFormProps> &
  CommonFilterFormProps;

const CommonFilterForm: React.FC<CombinedProps> = ({
  form,
  handleSubmit,
  fields,
  resetFiltersAction,
  totalCountSelector,
}) => {
  return (
    <FiltersForm
      form={form}
      handleSubmit={handleSubmit}
      fields={fields}
      resetFiltersAction={resetFiltersAction}
      totalCountSelector={totalCountSelector}
      resetFormAction={reset}
    />
  );
};

export default reduxForm<{}, CommonFilterFormProps>({
  form: "CommonFilterForm",
})(CommonFilterForm);

export type { CommonFilterFormProps };
