import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { carModelActions } from "redux/slices/carModel";
import CarModelService from "services/carModel.service";
import Chip from "components/atoms/Chip";
import { config } from "config";
import CarModelFilters from "../Filters/CarModelFilters";

export default function CarModelsList({ showFilters }: any) {
  const dispatch = useAppDispatch();
  const carModels = useAppSelector((state) => state.carModel.carModels);
  const loading = useAppSelector((state) => state.carModel.loading);
  const filters = useAppSelector((state) => state.carModel.filters);
  const refresh = useAppSelector((state) => state.carModel.refresh);
  // const refreshLoader = useAppSelector((state) => state.carModel.refreshLoader);
  const totalCount = useAppSelector((state) => state.carModel.totalCount);
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE)
  );

  useEffect(() => {
    return () => {
      dispatch(carModelActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    CarModelService.getCarModels(data);
  }, [filters, refresh, showFilters]);

  return (
    <>
      <TableContainer>
        {showFilters && <CarModelFilters />}
        <Box
          p={3}
          pt={0}
          bgcolor={"#ffffff"}
          borderRadius={"4px"}
          border={"1px solid #d3d3d3"}
        >
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              coloumns={7}
              loading={loading}
              length={carModels.length < 1 && loading ? 0 : carModels.length}
              message="No one has connected with our rental services yet"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Manufacturer</StyledTableCell>
                  <StyledTableCell>Category</StyledTableCell>
                  <StyledTableCell>Seats</StyledTableCell>
                  <StyledTableCell>Bags</StyledTableCell>
                  <StyledTableCell>Doors</StyledTableCell>
                  <StyledTableCell>Transmission</StyledTableCell>
                  <StyledTableCell>Air Conditioned</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carModels.map((carModel, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{carModel?.docNumber}</StyledTableCell>
                    <StyledTableCell>{carModel?.name}</StyledTableCell>
                    <StyledTableCell>{carModel?.manufacturer}</StyledTableCell>
                    <StyledTableCell>
                      {carModel?.carCategoryId?.name}
                    </StyledTableCell>
                    <StyledTableCell>{carModel?.numberOfSeats}</StyledTableCell>
                    <StyledTableCell>{carModel?.numberOfBags}</StyledTableCell>
                    <StyledTableCell>{carModel?.numberOfDoors}</StyledTableCell>
                    <StyledTableCell>{carModel?.transmission}</StyledTableCell>
                    <StyledTableCell>
                      {carModel?.isAirConditioned ? "Yes" : "No"}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Chip status={carModel?.currentStatus} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        className="detail-link"
                        to={`/car-model-details/${carModel._id}`}
                      >
                        Details
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
          {/* {!refreshLoader && showFilters && ( */}
          <Stack alignItems={"center"}>
            <Pagination
              variant="outlined"
              shape="rounded"
              page={filters.page}
              count={Math.ceil(totalPages ?? filters.pageSize)}
              onChange={(_e, page) =>
                dispatch(carModelActions.setPage({ page }))
              }
            />
          </Stack>
          {/* )} */}
        </Box>
      </TableContainer>
    </>
  );
}
