import http from "./http.service";
import Promisable from "./promisable.service";
import { modalActions } from "redux/slices/modal";
import { BookingType } from "redux/slices/booking";
import { loaderActions } from "redux/slices/loader";
import { getAppDispatch } from "utils/dispatch.util";
import { bookingActions } from "redux/slices/booking";
import { NavigateFunction } from "react-router-dom";

const url = "/api/bookings";

const BookingService = {
  getBooking: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const trip = success.data;
      dispatch?.(bookingActions.setBooking(trip));
    } else dispatch?.(bookingActions.setBooking({ data: "Not Found" }));

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },

  addAdminNote: async (id: string, note: { note: string }) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/admin-note/${id}`, note)
    );

    if (success) {
      const trip = success.data;

      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },

  updateBookingStatus: async (
    id: string,
    bookingStatus: { bookingStatus: string }
  ) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/update-status/${id}`, bookingStatus)
    );

    if (success) {
      const trip = success.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },

  refundBookingAmount: async (
    id: string,
    refundAmount: { refundAmount: number },
    navigate: NavigateFunction
  ) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));
    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/refund/${id}`, refundAmount)
    );

    if (success) {
      dispatch?.(modalActions.closeModal());
      navigate(`/booking-details/${id}`);
    }

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },

  getBookings: async (type: BookingType, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoading({ type, loading: true }));
    const queryParams = new URLSearchParams(data).toString();

    http.setJWT();
    const [success, error]: any = await Promisable.asPromise(
      http.get(
        type === "bookings"
          ? `${url}?${queryParams}`
          : `${url}?bookingStatus=${type}&${queryParams}`
      )
    );

    if (success) {
      const { bookings, totalCount } = success.data;
      dispatch?.(bookingActions.setBookings({ type, bookings }));
      dispatch?.(bookingActions.setTotalCount({ totalCount }));
    }

    dispatch?.(bookingActions.setLoading({ type, loading: false }));
    return [success, error];
  },

  acceptFromMarket: async (id: string, type: BookingType) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/${id}`)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
      dispatch?.(bookingActions.acceptBooking({ id, type, booking: trip }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  giveBackToMarket: async (id: string, data: any, type: BookingType) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/giveback/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(modalActions.closeModal());
      dispatch?.(bookingActions.setBooking(trip));
      dispatch?.(bookingActions.giveBack({ id, type, booking: trip }));
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },

  assignBooking: async (id: any, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(loaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/assign/${id}`, data)
    );

    if (success) {
      const { trip } = success.data.data;
      dispatch?.(bookingActions.setBooking(trip));
    }

    dispatch?.(loaderActions.setLoading(false));
    return [success, error];
  },

  bookingConfirmation: async (id: string, accepted: boolean) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookingActions.setLoader(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}/confirmation/${id}?accepted=${accepted}`)
    );

    dispatch?.(bookingActions.setLoader(false));
    return [success, error];
  },
};

export default BookingService;
