import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import TableLoadingWrapper from "components/templates/TableLoadingWrapper";
import { StyledTableRow, StyledTableCell } from "components/templates/Tables";
import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Box,
  Stack,
  Pagination,
} from "@mui/material";
import { carExtraActions } from "redux/slices/carExtra";
import CarExtraService from "services/carExtra.service";
import Chip from "components/atoms/Chip";
import { config } from "config";
import CarExtraFilters from "../Filters/CarExtraFilters";

export default function CarExtrasList({ showFilters }: any) {
  const dispatch = useAppDispatch();
  const carExtras = useAppSelector((state) => state.carExtra.carExtras);
  const loading = useAppSelector((state) => state.carExtra.loading);
  const filters = useAppSelector((state) => state.carExtra.filters);
  const refresh = useAppSelector((state) => state.carExtra.refresh);
  // const refreshLoader = useAppSelector((state) => state.carExtra.refreshLoader);
  const totalCount = useAppSelector((state) => state.carExtra.totalCount);
  const totalPages = Math.ceil(
    totalCount / (filters.pageSize ?? config.PAGE_SIZE)
  );

  useEffect(() => {
    return () => {
      dispatch(carExtraActions.resetPage());
    };
  }, [dispatch]);

  useEffect(() => {
    const data = showFilters ? filters : null;
    CarExtraService.getCarExtras(data);
  }, [filters, refresh, showFilters]);

  return (
    <>
      <TableContainer>
        {showFilters && <CarExtraFilters />}
        <Box
          p={3}
          pt={0}
          bgcolor={"#ffffff"}
          borderRadius={"4px"}
          border={"1px solid #d3d3d3"}
        >
          <Table
            aria-label="customized table"
            sx={{
              minWidth: "100%",
              borderSpacing: "0 10px",
              borderBottomWidth: "0px",
              borderCollapse: "separate",
            }}
          >
            <TableLoadingWrapper
              coloumns={7}
              loading={loading}
              length={carExtras.length < 1 && loading ? 0 : carExtras.length}
              message="No one has connected with our rental services yet"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Model</StyledTableCell>
                  <StyledTableCell>Supplier</StyledTableCell>
                  <StyledTableCell>Extras</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell align="center"></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {carExtras.map((carExtra: any, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{carExtra?.docNumber}</StyledTableCell>
                    <StyledTableCell>
                      {carExtra?.modelId?.manufacturer} <br />
                      {carExtra?.modelId?.name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {carExtra?.supplierId?.name} <br />{" "}
                      {carExtra?.supplierId?.email}
                    </StyledTableCell>
                    <StyledTableCell>
                      {carExtra?.extras?.length > 0 &&
                        carExtra?.extras?.map((extra: any, index: number) => (
                          <>
                            <b>{extra?.name}</b>: {extra?.dailyRate?.toFixed(2)}{" "}
                            SAR <br />
                          </>
                        ))}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Chip status={carExtra?.currentStatus} />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Link
                        className="detail-link"
                        to={`/car-extra-details/${carExtra._id}`}
                      >
                        Details
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </TableLoadingWrapper>
          </Table>
          {/* {!refreshLoader && showFilters && ( */}
          <Stack alignItems={"center"}>
            <Pagination
              variant="outlined"
              shape="rounded"
              page={filters.page}
              count={Math.ceil(totalPages ?? filters.pageSize)}
              onChange={(_e, page) =>
                dispatch(carExtraActions.setPage({ page }))
              }
            />
          </Stack>
          {/* )} */}
        </Box>
      </TableContainer>
    </>
  );
}
