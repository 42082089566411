import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { carModelActions } from "redux/slices/carModel";
import ImageService from "./image.service";

const url = "/api/non-api/car-model";

const CarModelService = {
  getCarModel: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const carModel = success.data;
      dispatch?.(carModelActions.setCarModel(carModel));
    } else dispatch?.(carModelActions.setCarModel({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getCarModels: async (data: any = {}) => {
    const dispatch = getAppDispatch();
    dispatch?.(carModelActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();
    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(
        carModelActions.setCarModels({ carModels: documents, totalCount })
      );
    }

    dispatch?.(carModelActions.setLoading(false));
    return [success, error];
  },

  addCarModel: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();
    http.setMultiPart();
    const formData = new FormData();

    // Convert businessLogo to a file
    let img = await ImageService.getImageFileFromBlob(data.carImage);

    // Append the image
    formData.append("photo", img);

    // Append other values to formData
    formData.append("name", data.name);
    formData.append("manufacturer", data.manufacturer);
    formData.append("carCategoryId", data.carCategoryId);
    formData.append("isAirConditioned", data.isAirConditioned);
    formData.append("transmission", data.transmission);
    formData.append("numberOfSeats", data.numberOfSeats);
    formData.append("numberOfBags", data.numberOfBags);
    formData.append("numberOfDoors", data.numberOfDoors);
    formData.append("acrissCode", data.acrissCode);

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, formData)
    );

    if (success) {
      const carModel = success.data;
      dispatch?.(carModelActions.addCarModel(carModel));
      navigate?.("/car-models");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateCarModel: async (id: string, data: any, navigate: NavigateFunction) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    http.setMultiPart();
    dispatch?.(formLoaderActions.setLoading(true));
    const formData = new FormData();

    const keysToAppend = [
      "name",
      "manufacturer",
      "carCategoryId",
      "isAirConditioned",
      "transmission",
      "numberOfSeats",
      "numberOfBags",
      "numberOfDoors",
      "acrissCode",
    ];

    // formData.append("photo", img);
    if (data.carImage) {
      let img = await ImageService.getImageFileFromBlob(data.carImage);
      formData.append("photo", img);
    }
    keysToAppend.forEach((key) => {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    });
    // Append other values to formData
    // formData.append("name", data.name);
    // formData.append("manufacturer", data.manufacturer);
    // formData.append("carCategoryId", data.carCategoryId);
    // formData.append("isAirConditioned", data.isAirConditioned);
    // formData.append("transmission", data.transmission);
    // formData.append("numberOfSeats", data.numberOfSeats);
    // formData.append("numberOfBags", data.numberOfBags);
    // formData.append("numberOfDoors", data.numberOfDoors);
    // formData.append("acrissCode", data.acrissCode);

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, formData)
    );

    if (success) {
      const carModel = success.data;
      dispatch?.(carModelActions.setCarModel(carModel));
      navigate?.(`/car-model-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteCarModel: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(carModelActions.deleteCarModelById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default CarModelService;
