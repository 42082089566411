import { config } from "config";
import { SetInsuranceFiltersPayload, SetTotalCountPayload, SetInsurancesPayload, InsuranceState, SetPagePayload } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const default_page_size = config.PAGE_SIZE;
const initialState: InsuranceState = {
  tab: 0,
  refresh: 0,
  refreshLoader: false,
  count: 0,
  totalCount: 0,
  documents: [],
  insurance: null,
  loading: true,
  current_filters: {},
  filters: { page: 1, pageSize: default_page_size },
};

export const insuranceSlice = createSlice({
  name: "insurance",
  initialState,
  reducers: {
    refresh: (state) => {
      state.refresh += 1;
      state.refreshLoader = true;
    },
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addInsurance: (state, action) => {
      state.documents.unshift(action.payload);
    },
    updateInsurance: (state, action) => {
      const { id, insurance } = action.payload;
      state.documents.every(({ _id }, i) => {
        if (id === _id) {
          state.documents[i] = insurance;
          return false;
        }
        return true;
      });
    },
    deleteInsuranceById: (state, action) => {
      const id = action.payload;
      state.documents.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.documents.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setInsurance: (state, action) => {
      state.insurance = action.payload;
    },
    setInsurances: (state, action: PayloadAction<SetInsurancesPayload>) => {
      const { documents } = action.payload;
      state.documents = documents;
      state.refreshLoader = false;
    },
    setTotalCount: (state, action: PayloadAction<SetTotalCountPayload>) => {
      const { totalCount } = action.payload;
      state.totalCount = totalCount;
      // state.refresh += 1;
      // state.refreshLoader = true;
    },
    setPage: (state, action: PayloadAction<SetPagePayload>) => {
      const { page } = action.payload;
      state.filters.page = page;
      state.refresh += 1;
      state.refreshLoader = true;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action: PayloadAction<SetInsuranceFiltersPayload>) => {
      const { data } = action.payload;
      state.filters = data;
      state.refreshLoader = true;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const insuranceReducer = insuranceSlice.reducer;

export const insuranceActions = insuranceSlice.actions;
export default insuranceReducer;
