import { Container } from "@mui/material";
import BranchesList from "./BranchesList";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "components/templates/PageHeader/PageHeader";

export default function Branches() {
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="xl">
        <PageHeader
          title="Branches"
          renderRight={
            <Button variant="contained" onClick={() => navigate("/add-branch")}>
              Add Branch
            </Button>
          }
        />
        <BranchesList showFilters />
      </Container>
    </div>
  );
}
