import { config } from "config";
import { useAppDispatch } from "redux/hooks";
import Filters from "components/templates/Filters";
import { supplierActions } from "redux/slices/supplier";

export default function SuppliersFilters() {
  const form = "SuppliersFiltersForm";
  const dispatch = useAppDispatch();

  const handleSubmit = (values: any) => {
    const default_page_size = config.PAGE_SIZE;
    let data: any = { page: 1, pageSize: default_page_size };

    if (values.docNumber) data.docNumber = values.docNumber;
    if (values.name) data.name = values.name;
    if (values.email) data.email = values.email;
    if (values.phoneNumber) data.phoneNumber = values.phoneNumber.value;
    if (values.taxId) data.taxId = values.taxId;
    if (values.registrationNumber)
      data.registrationNumber = values.registrationNumber;
    if (values.mainContactPerson)
      data.mainContactPerson = values.mainContactPerson;
    if (values.pageSize) data.pageSize = values.pageSize;
    dispatch(supplierActions.setFilters(data));
  };
  const formFields = [
    "docNumber",
    "name",
    "email",
    "phoneNumber",
    "mainContactPerson",
    "registrationNumber",
    "taxId",
    "pageSize",
  ];

  return (
    <div className="filters">
      <Filters
        handleSubmit={handleSubmit}
        filterKeys={formFields}
        formName={form}
        resetFiltersAction={() => dispatch(supplierActions.resetFilters())}
        totalCountSelector={(state: any) => state.supplier.totalCount}
      />
    </div>
  );
}
