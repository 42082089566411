import { useState } from "react";
import { InputOwnProps } from ".";
import TextField from "@mui/material/TextField";
import styled from "@mui/material/styles/styled";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormControl, InputLabel } from "@mui/material";

export type InputProps = InputOwnProps & React.ComponentProps<typeof TextField>;

const BootstrapInput = styled(TextField)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2.5),
  },
  "& .Mui-disabled": {
    backgroundColor: "transparent !important",
    opacity: "0.5",
    cursor: "not-allowed",
  },
  "& .MuiFilledInput-root:after, .MuiFilledInput-root:before": {
    display: "none",
  },

  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiFilledInput-root": {
    borderRadius: 4,
    width: "100%",
    position: "relative",
    backgroundColor: "transparent",
    border: "1px solid",
    borderColor: "#dbdbdb",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "background-color"]),
    "&:focus": {
      boxShadow: `none`,
      background: "none",
      borderColor: "#ed1c24",
    },
  },
  "& .MuiFilledInput-root:not(.Mui-disabled):hover": {
    backgroundColor: "transparent !important",
    borderColor: "#ed1c24",
  },
  "& .Mui-focused": {
    backgroundColor: "transparent !important",
    borderColor: "#ed1c24",
  },
  "& .MuiInputBase-input": {
    padding: 0,
    height: "auto",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
  },
}));

export default function Input({ showIcon, type, ...rest }: InputProps) {
  const [show, setShow] = useState(false);
  return (
    <FormControl fullWidth variant="standard">
      {rest?.label && (
        <InputLabel shrink htmlFor={`${rest?.label}`}>
          {rest?.label}
        </InputLabel>
      )}
      <BootstrapInput
        fullWidth
        variant="filled"
        {...rest}
        type={show && showIcon && type === "password" ? "text" : type}
        InputProps={{
          endAdornment: showIcon && type === "password" && (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                onClick={() => setShow(!show)}
                onMouseDown={(e) => e.preventDefault()}
                aria-label="toggle password visibility"
              >
                {show ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          ...rest.InputProps,
        }}
        hiddenLabel
      />
    </FormControl>
  );
}
