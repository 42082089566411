import { useEffect } from "react";
import { supplierActions } from "redux/slices/supplier";
import SelectRedux from "components/molecules/SelectRedux";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import CarModelService from "services/carModel.service";

export default function SelectCarModel(props: any) {
  // const { id } = useParams();
  const dispatch = useAppDispatch();
  const carModels = useAppSelector(
    (state) => state.carModel.carModelOptions
  );

  useEffect(() => {
    CarModelService.getCarModels();

    return () => {
      dispatch(supplierActions.clear());
    };
  }, [dispatch]);

  return (
    <SelectRedux
      {...props}
      options={carModels}
    // disabled={id ? true : false}
    />
  );
}
