import { Container } from "@mui/material";
import FranchisesList from "./FranchisesList";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "components/templates/PageHeader/PageHeader";

export default function Franchises() {
  const navigate = useNavigate();
  return (
    <div>
      <Container maxWidth="xl">
        <PageHeader
          title="Franchises"
          renderRight={
            <Button variant="contained" onClick={() => navigate("/add-franchise")}>
              Add Franchise
            </Button>
          }
        />
        <FranchisesList showFilters />
      </Container>
    </div>
  );
}
