import GoBack from "components/atoms/GoBack";
import { useAppSelector } from "redux/hooks";
import Container from "@mui/material/Container";
import CircleLoader from "components/atoms/CircleLoader";
import { useNavigate, useParams } from "react-router-dom";
import AddEmployeeForm from "./AddEmployeeForm";
import UpdateEmployeeForm from "./UpdateEmployeeForm";
import EmployeeService from "services/employee.service";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import { phoneFormat } from "utils/phone.util";

export default function AddEmployee() {
  const { id } = useParams();
  const navigate = useNavigate();
  const employee = useAppSelector((state) => state.employee.employee);
  const loading = useAppSelector((state) => state.formLoader.loading);

  const handleSubmit = (values: any) => {
    let data = { ...values };
    
    data.phoneNumber = phoneFormat(
      values?.phone?.data?.countryCode ? values?.phone : employee?.phoneNumber
    );

    data.address = values.address.address;

    delete data.phone;

    if (values.address.address._id) {
      const { _id, ...addressWithoutId } = values.address.address;
      data.address = addressWithoutId;
    }

    if (id) {
      EmployeeService.updateEmployee(`${id}`, data, navigate);
    } else EmployeeService.addEmployee(data, navigate);
  };

  return (
    <div>
      <Container maxWidth="xl">
        <GoBack
          path={`${id && employee ? `/employee-details/${id}` : "/employees"}`}
          title={`Back to ${id && employee ? "Employee Details" : "Employees"} `}
        />
        <PageHeader title={`${id ? "Update" : "Add"} Employee`} />
        <div className="form">
          {loading && <CircleLoader />}
          {id && employee === "Not found" ? (
            <p>Employee Not Found</p>
          ) : (
            <AddEmployeeForm onSubmit={handleSubmit} />
          )}
          {id && <UpdateEmployeeForm id={id} />}
        </div>
      </Container>
    </div>
  );
}
