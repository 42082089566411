import AddBranch from "pages/AddBranch";
import AddCar from "pages/AddCar";
import AddCarCategory from "pages/AddCarCategory";
import AddCarExtra from "pages/AddCarExtra";
import AddCarModel from "pages/AddCarModel";
import AddCarPrice from "pages/AddCarPrice";
import AddCarSpecs from "pages/AddCarSpecs";
import AddEmployee from "pages/AddEmployee";
import AddFranchise from "pages/AddFranchise";
import AddInsurance from "pages/AddInsurance";
import AddPromotion from "pages/AddPromotion";
import AddSupplier from "pages/AddSupplier";
import BranchDetails from "pages/BranchDetails";
import Branches from "pages/Branches";
import CarCategories from "pages/CarCategories";
import CarDetails from "pages/CarDetails";
import CarExtraDetails from "pages/CarExtraDetails";
import CarExtras from "pages/CarExtras";
import CarModelDetails from "pages/CarModelDetails";
import CarModels from "pages/CarModels";
import CarPrice from "pages/CarPrice";
import CarPriceDetails from "pages/CarPriceDetails";
import CarSpecs from "pages/CarSpecs";
import CarSpecsDetails from "pages/CarSpecsDetails";
import Cars from "pages/Cars";
import EmployeeDetails from "pages/EmployeeDetails";
import Employees from "pages/Employees";
import FranchiseDetails from "pages/FranchiseDetails";
import Franchises from "pages/Franchises";
import InsuranceDetails from "pages/InsuranceDetails";
import Promotion from "pages/Promotion";
import PromotionDetails from "pages/PromotionDetails";
import SupplierDetails from "pages/SupplierDetails";
import Suppliers from "pages/Suppliers";
import UpdateBooking from "pages/UpdateBooking";
import { lazy } from "react";

const Login = lazy(() => import("pages/Login"));
const PasswordOTP = lazy(() => import("pages/PasswordOTP"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/ForgotPassword"));

const Profile = lazy(() => import("pages/Profile"));
const Dashboard = lazy(() => import("pages/Dashboard"));

const AddBooking = lazy(() => import("pages/AddBooking"));
const SearchFleet = lazy(() => import("pages/AddBooking/SearchFleet"));

const Bookings = lazy(() => import("pages/Bookings"));
const BookingDetails = lazy(() => import("pages/BookingDetails"));

const Insurance = lazy(() => import("pages/Insurance"));

const Users = lazy(() => import("pages/Users"));
const AddUser = lazy(() => import("pages/AddUser"));
const UserDetails = lazy(() => import("pages/UserDetails"));

const Companies = lazy(() => import("pages/Companies"));
const AddCompany = lazy(() => import("pages/AddCompany"));
const CompanyDetails = lazy(() => import("pages/CompanyDetails"));

const Price = lazy(() => import("pages/Price"));
const AddPrice = lazy(() => import("pages/AddPrice"));
const PriceDetails = lazy(() => import("pages/PriceDetails"));

const Tickets = lazy(() => import("pages/Tickets"));

export { default } from "./AppRoutes";

export interface IRoute {
  path: string;
  element: JSX.Element;
}

export const public_routes: IRoute[] = [
  { path: "/", element: <Login /> },
  { path: "/password-otp", element: <PasswordOTP /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
];

export const private_routes: IRoute[] = [
  { path: "/profile", element: <Profile /> },
  { path: "/dashboard", element: <Dashboard /> },

  { path: "/search-fleet", element: <SearchFleet /> },
  { path: "/add-booking", element: <AddBooking /> },
  { path: "/update-booking/:id", element: <UpdateBooking /> },

  { path: "/upcoming-bookings", element: <Bookings type={"confirm"} /> }, //market
  { path: "/ongoing-bookings", element: <Bookings type={"inprogress"} /> }, // planned
  { path: "/past-bookings", element: <Bookings type="finish" /> }, //completed
  { path: "/bookings", element: <Bookings type="bookings" /> },
  { path: "/booking-details/:id", element: <BookingDetails /> },

  { path: "/insurance", element: <Insurance /> },
  { path: "/insurance-details/:id", element: <InsuranceDetails /> },
  { path: "/update-insurance/:id", element: <AddInsurance /> },
  { path: "/add-insurance", element: <AddInsurance /> },

  { path: "/users", element: <Users /> },
  { path: "/add-user", element: <AddUser /> },
  { path: "/update-user/:id", element: <AddUser /> },
  { path: "/user-details/:id", element: <UserDetails /> },

  { path: "/companies", element: <Companies /> },
  { path: "/add-company", element: <AddCompany /> },
  { path: "/update-company/:id", element: <AddCompany /> },
  { path: "/company-details/:id", element: <CompanyDetails /> },

  { path: "/employees", element: <Employees /> },
  { path: "/add-employee", element: <AddEmployee /> },
  { path: "/update-employee/:id", element: <AddEmployee /> },
  { path: "/employee-details/:id", element: <EmployeeDetails /> },

  { path: "/franchises", element: <Franchises /> },
  { path: "/add-franchise", element: <AddFranchise /> },
  { path: "/update-franchise/:id", element: <AddFranchise /> },
  { path: "/franchise-details/:id", element: <FranchiseDetails /> },

  { path: "/suppliers", element: <Suppliers /> },
  { path: "/add-supplier", element: <AddSupplier /> },
  { path: "/update-supplier/:id", element: <AddSupplier /> },
  { path: "/supplier-details/:id", element: <SupplierDetails /> },

  { path: "/branches", element: <Branches /> },
  { path: "/add-branch", element: <AddBranch /> },
  { path: "/update-branch/:id", element: <AddBranch /> },
  { path: "/branch-details/:id", element: <BranchDetails /> },

  { path: "/car-prices", element: <CarPrice /> },
  { path: "/add-car-price", element: <AddCarPrice /> },
  { path: "/update-car-price/:id", element: <AddCarPrice /> },
  { path: "/car-price-details/:id", element: <CarPriceDetails /> },

  { path: "/cars", element: <Cars /> },
  { path: "/add-sup-car", element: <AddCar /> },
  { path: "/update-sup-car/:id", element: <AddCar /> },
  { path: "/car-details/:id", element: <CarDetails /> },

  { path: "/car-extras", element: <CarExtras /> },
  { path: "/add-car-extra", element: <AddCarExtra /> },
  { path: "/update-car-extra/:id", element: <AddCarExtra /> },
  { path: "/car-extra-details/:id", element: <CarExtraDetails /> },

  { path: "/car-categories", element: <CarCategories /> },
  { path: "/add-car-category", element: <AddCarCategory /> },
  { path: "/update-car-category/:id", element: <AddCarCategory /> },
  { path: "/car-category-details/:id", element: <AddCarCategory /> },

  { path: "/car-models", element: <CarModels /> },
  { path: "/add-car-model", element: <AddCarModel /> },
  { path: "/update-car-model/:id", element: <AddCarModel /> },
  { path: "/car-model-details/:id", element: <CarModelDetails /> },

  { path: "/car-specs", element: <CarSpecs /> },
  { path: "/add-car-specs", element: <AddCarSpecs /> },
  { path: "/update-car-specs/:id", element: <AddCarSpecs /> },
  { path: "/car-specs-details/:id", element: <CarSpecsDetails /> },

  { path: "/price-engine", element: <Price /> },
  { path: "/add-price", element: <AddPrice /> },
  { path: "/update-price/:id", element: <AddPrice /> },
  { path: "/price-details/:id", element: <PriceDetails /> },

  { path: "/promotions", element: <Promotion /> },
  { path: "/add-promotion", element: <AddPromotion /> },
  { path: "/update-promotion/:id", element: <AddPromotion /> },
  { path: "/promotion-details/:id", element: <PromotionDetails /> },

  { path: "/tickets", element: <Tickets /> },
];
