const ImageService = {
  getImageFileFromBlob: async (image: any) => {
    const response = await fetch(image?.blob!);
    const data = await response.blob();
    return new File([data], image?.name, {
      type: response.headers.get("content-type") || image?.type,
    });
  },
};

export default ImageService;
