import InputRedux from "components/molecules/InputRedux";
import { ReduxFormField } from "components/molecules/ReduxFormFields/index";
import { required } from "utils/validate.util";
import ComboBoxRedux from "components/molecules/ComboBoxRedux";
import SelectRedux from "components/molecules/SelectRedux";
import CheckboxRedux from "components/molecules/CheckboxRedux";

export { default } from "./AddInsuranceForm";

export const InsServiceOpts = [
  { label: "Collision Coverage", value: "collision coverage" },
  { label: "All Types of Tyre Puncture", value: "all types of tyre puncture" },
  { label: "Theft Protection", value: "theft protection" },
  { label: "Damage By Negligence", value: "damage by negligence" },
  { label: "Minor Scratches", value: "minor scratches" },
  { label: "Mechanical Failure", value: "mechanical failure" },
]

export const fields: ReduxFormField[] = [
  {
    name: "currentStatus",
    label: "Status",
    component: CheckboxRedux,
    cellProps: { sm: 12, md: 12 },
  },
  {
    name: "name",
    label: "Name",
    validate: [required],
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
  },
  {
    name: "description",
    label: "Description",
    validate: [required],
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
  },
  {
    name: "info",
    label: "Info",
    validate: [required],
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
  },
  {
    name: "excludes",
    label: "Excludes",
    component: ComboBoxRedux,
    cellProps: { sm: 6, md: 4 },
    ComboBoxProps: {
      multiple: true,
      freeSolo: false,
      options: InsServiceOpts,
    },
  },
  {
    name: "zero_to_one",
    label: "0 to 100 SAR",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  {
    name: "one_to_two",
    label: "100 to 200 SAR",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  {
    name: "two_to_three",
    label: "200 to 300 SAR",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  {
    name: "three_to_four",
    label: "300 to 400 SAR",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
  {
    name: "more_than_four",
    label: "More than 400 SAR",
    component: InputRedux,
    cellProps: { sm: 6, md: 4 },
    InputProps: { size: "small" },
  },
];
