import { useEffect } from "react";
import {
  Chip as MUIChip,
  Container,
  Grid,
  Stack,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoBack from "components/atoms/GoBack";
import Button from "components/atoms/Button";
import styles from "./CarExtraDetails.module.css";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Chip from "components/atoms/Chip";
import { PageHeader } from "components/templates/PageHeader/PageHeader";
import CircleLoader from "components/atoms/CircleLoader";
import CarExtraService from "services/carExtra.service";
import { carExtraActions } from "redux/slices/carExtra";
import { StyledTableCell } from "components/templates/Tables";

export default function CarExtraDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const carExtra = useAppSelector((state) => state.carExtra.carExtra);
  const loading = useAppSelector((state) => state.formLoader.loading);

  useEffect(() => {
    CarExtraService.getCarExtra(id || "");
    return () => {
      dispatch(carExtraActions.setCarExtra(null));
    };
  }, [id, dispatch]);

  return (
    <Container maxWidth="xl">
      <GoBack path="/car-extras" title="Back to Car Extras" />
      <PageHeader title="Car Extra Details" />
      {loading && <CircleLoader />}
      {!loading && !carExtra ? (
        <p>Car Extra Not Found</p>
      ) : (
        <div className="form">
          <Stack direction={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <Chip status={carExtra?.currentStatus} /> &nbsp;
            <Button
              variant="text"
              onClick={() => navigate(`/update-car-extra/${carExtra._id}`)}
            >
              Edit
            </Button>
          </Stack>
          <Grid container>
            <Grid item md={6} lg={3}>
              <div className={styles.fieldBox}>
                <label htmlFor=""> ID</label>
                <p>{carExtra?.docNumber}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Name</label>
                <p>{carExtra?.modelId?.name}</p>
              </div>
              <div className={styles.fieldBox}>
                <label htmlFor="">Manufacturer</label>
                <p>{carExtra?.modelId?.manufacturer}</p>
              </div>
            </Grid>
            <Grid item md={6} lg={3}>
              <div className={styles.fieldBox}>
                <h4 style={{ margin: 0 }}>Supplier Info</h4>
                <Stack direction={"row"} gap={1}>
                  <label htmlFor="">Name:</label>
                  <Link
                    to={`/supplier-details/${carExtra?.supplierId?._id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {carExtra?.supplierId?.name}
                  </Link>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <label htmlFor="">Contact Person:</label>
                  <p>{carExtra?.supplierId?.mainContactPerson}</p>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <label htmlFor="">Phone:</label>
                  <p>
                    {carExtra?.supplierId?.phoneNumber?.dialingCode +
                      carExtra?.supplierId?.phoneNumber?.number}
                  </p>
                </Stack>
                <Stack direction={"row"} gap={1}>
                  <label htmlFor="">Email:</label>
                  <p>{carExtra?.supplierId?.email}</p>
                </Stack>
              </div>
            </Grid>
            <Grid item md={12} lg={6}>
              <div style={{ textAlign: "right" }}>
                <img
                  src={carExtra?.modelId?.carImage}
                  alt={carExtra?.modelId?.name}
                  style={{ height: "200px", maxWidth: "100%" }}
                />
              </div>
            </Grid>
          </Grid>

          <h4 style={{ marginTop: 0, marginBottom: "8px" }}>Extras</h4>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ paddingLeft: 0 }}>Name</TableCell>
                <TableCell>Daily Rate</TableCell>
                <TableCell>Max Buying Capacity</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {carExtra?.extras?.map((extra: any, index: number) => (
                <TableRow>
                  <StyledTableCell  sx={{ paddingLeft: 0 }}>{extra?.name}</StyledTableCell>
                  <StyledTableCell>
                    {extra?.dailyRate === 0
                      ? "Free"
                      : `${extra?.dailyRate.toFixed(2)} SAR`}
                  </StyledTableCell>
                  <StyledTableCell>{extra?.maxBuyingCapacity}</StyledTableCell>
                  <StyledTableCell>{extra?.description}</StyledTableCell>
                  <StyledTableCell>
                    <MUIChip
                      size="small"
                      variant="outlined"
                      color={extra?.isActive ? "success" : "default"}
                      label={extra?.isActive ? "Active" : "InActive"}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </Container>
  );
}
