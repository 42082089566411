import { useAppSelector } from "redux/hooks";
import UpdateBookingForm from "./UpdateBookingForm";
import UpdateBookingService from "services/addBooking.service";
import { useNavigate } from "react-router-dom";
import DateService from "utils/date.util";
import { format } from "date-fns";

export default function UpdateBooking() {
  const navigate = useNavigate();

  const allExtras = useAppSelector((state) => state.addBooking.extras);
  const branches = useAppSelector((state) => state.branch?.branches);
  const booking = useAppSelector((state) => state.booking.booking);
  const users = useAppSelector((state) => state.user.documents);

  const userId = useAppSelector(
    (state) => state.form?.["UpdateBookingForm"]?.values?.userId
  );

  const handleSubmit = (values: any) => {
    const extras = allExtras?.extras?.filter(
      (extra: any) => values?.[extra.id]
    );

    const insurances = allExtras?.insurances?.find(
      (insurance: any) => insurance.id === values?.insurance
    );

    const selectedUser = values?.userId
      ? users.find((user) => user._id === values?.userId)
      : null;

    let phoneNumber = {};
    if (!values?.userId) {
      const dialCodeLength = values?.phone?.data?.dialCode?.length || 0;
      const phoneNumberWithoutDialCode =
        values?.phone?.value?.slice(dialCodeLength);
      phoneNumber = {
        number: phoneNumberWithoutDialCode,
        dialingCode: values?.phone.data?.dialCode,
        countryCode: values.phone.data.countryCode,
      };
    }

    const filteredBranch = branches.find((doc) => doc._id === values?.pickup);

    let pickupDate = DateService.splitDateAndCombine(
      `${values.dates?.date[0]}Z` === booking.pickupDate
        ? `${values.dates?.date[0]}Z`
        : format(values.dates?.date[0], "yyyy-MM-dd'T'HH:mm:ss'.000Z'"),
      `${values.pickupTime?.date}Z` === booking.pickupDate
        ? `${values.pickupTime?.date}Z`
        : format(values.pickupTime?.date, "yyyy-MM-dd'T'HH:mm:ss'.000Z'")
    );
    let dropOffDate = DateService.splitDateAndCombine(
      `${values.dates?.date[1]}Z` === booking.dropOffDate
        ? `${values.dates?.date[1]}Z`
        : format(values.dates?.date[1], "yyyy-MM-dd'T'HH:mm:ss'.000Z'"),
      `${values.dropOffTime?.date}Z` === booking.dropOffDate
        ? `${values.dropOffTime?.date}Z`
        : format(values.dropOffTime?.date, "yyyy-MM-dd'T'HH:mm:ss'.000Z'")
    );

    let payload = {
      pickupDate,
      dropOffDate,
      pickupBranchId: filteredBranch?._id ?? booking?.pickupBranch?.id,
      dropOffBranchId: filteredBranch?._id ?? booking?.dropOffBranch?.id,
      // carId: booking?.carId,
      supplierId: booking?.supplierId,
      mainDriver: {
        email: values.email,
        phoneNumber: values?.userId ? selectedUser?.phoneNumber : phoneNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        countryOfResidence: "SA",
      },
      ...(booking?.billingAddress && {
        billingAddress: values?.billingAddress,
      }),
      ...(extras?.length > 0 && {
        extras: extras.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            quantity: 1,
            totalPrice: Number(item.totalAmount),
          };
        }),
      }),
      ...(insurances && {
        insurances: [
          {
            id: insurances.id,
            name: insurances.name,
            quantity: 1,
            totalPrice: Number(insurances?.totalAmount),
          },
        ],
      }),
      // _metadata: "66cdb537be5cb53f49af282266cdb537be5cb53f49af282266cdb537be5cb53f49af2822",
      ...(booking?.userId !== userId && { userId: values?.userId }),
      userNotes: "",
      isPaid: values?.isPaid,
      ...(localStorage.getItem("totalPrice")
        ? { adminTotalPrice: Number(localStorage.getItem("totalPrice")) }
        : {}),
    };

    UpdateBookingService.updateBooking(booking?._id, payload, navigate);
  };

  return <UpdateBookingForm onSubmit={handleSubmit} />;
}
