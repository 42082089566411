import http from "./http.service";
import Promisable from "./promisable.service";
import { getAppDispatch } from "utils/dispatch.util";
import { modalActions } from "redux/slices/modal/modalSlice";
import { NavigateFunction } from "react-router-dom";
import { formLoaderActions } from "redux/slices/formLoader";
import { franchiseActions } from "redux/slices/franchise";

const url = "/api/b2b/franchise";

const FranchiseService = {
  getFranchise: async (id: string) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}/${id}`)
    );

    if (success) {
      const franchise = success.data;
      dispatch?.(franchiseActions.setFranchise(franchise));
    } else dispatch?.(franchiseActions.setFranchise({ data: null }));

    dispatch?.(formLoaderActions.setLoading(false));

    return [success, error];
  },

  getFranchises: async (data: any) => {
    const dispatch = getAppDispatch();

    dispatch?.(franchiseActions.setLoading(true));

    http.setJWT();
    const queryParams = new URLSearchParams(data).toString();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`${url}?${queryParams}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(franchiseActions.setFranchises({ documents }));
      dispatch?.(franchiseActions.setTotalCount({ totalCount }))
    }

    dispatch?.(franchiseActions.setLoading(false));
    return [success, error];
  },

  getCompanyFranchise: async (id: string) => {
    const dispatch = getAppDispatch();

    dispatch?.(franchiseActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.get(`/api/b2b/franchise?companyId=${id}`)
    );

    if (success) {
      const { documents, totalCount } = success.data;
      dispatch?.(franchiseActions.setFranchises({ documents }));
      dispatch?.(franchiseActions.setTotalCount({ totalCount }))
    }

    dispatch?.(franchiseActions.setLoading(false));
    return [success, error];
  },

  addFranchise: async (data: any, navigate?: NavigateFunction) => {
    const dispatch = getAppDispatch();
    dispatch?.(formLoaderActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.post(`${url}`, data)
    );

    if (success) {
      const franchise = success.data;
      dispatch?.(franchiseActions.addFranchise(franchise));
      navigate?.("/franchises");
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  updateFranchise: async (
    id: string,
    data: any,
    navigate: NavigateFunction
  ) => {
    const dispatch = getAppDispatch();
    http.setJWT();
    dispatch?.(formLoaderActions.setLoading(true));

    const [success, error]: any = await Promisable.asPromise(
      http.patch(`${url}/${id}`, data)
    );

    if (success) {
      const franchise = success.data;
      dispatch?.(franchiseActions.setFranchise(franchise));
      navigate?.(`/franchise-details/${id}`);
    }

    dispatch?.(formLoaderActions.setLoading(false));
    return [success, error];
  },

  deleteFranchise: async (id: string, data: any) => {
    const dispatch = getAppDispatch();
    dispatch?.(modalActions.setLoading(true));

    http.setJWT();

    const [success, error]: any = await Promisable.asPromise(
      http.delete(`${url}/${id}`, { data })
    );

    if (success) {
      dispatch?.(franchiseActions.deleteFranchiseById(id));
      dispatch?.(modalActions.closeModal());
    }

    dispatch?.(modalActions.setLoading(false));
    return [success, error];
  },
};

export default FranchiseService;
